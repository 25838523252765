import { Payment, PaymentPrepareRq, PaymentPrepareResult, PaymentUpdateRq, PaymentUpdateRp } from "./types";
import { ServiceCall } from "./serviceCall";
import config from "../config";

const functionsBaseURL = config.functionsBaseURL;

export async function getPayment(paymentID: string): Promise<Payment> {
  const call = new ServiceCall(null);
  return (await call.Get(`${functionsBaseURL}payment/get?key=${paymentID}`, undefined)) as Payment;
}

export async function getPaymentStatus(paymentID: string): Promise<{ paid: boolean; redirectURL: string }> {
  const call = new ServiceCall(null);
  return (await call.Get(`${functionsBaseURL}payment/checkPaymentStatus/${paymentID}`, undefined)) as {
    paid: boolean;
    redirectURL: string;
  };
}

export async function preparePayment(info: PaymentPrepareRq): Promise<PaymentPrepareResult> {
  const call = new ServiceCall(null);
  return (await call.Post(`${functionsBaseURL}payment/preparePayment`, info)) as PaymentPrepareResult;
}

export async function updatePayment(info: PaymentUpdateRq): Promise<PaymentUpdateRp> {
  const call = new ServiceCall(null);
  return (await call.Post(`${functionsBaseURL}payment/updatePayment`, info)) as PaymentUpdateRp;
}

interface DataURL {
  dataURL: string;
}
export async function urlToDataURL(url: string): Promise<string> {
  const call = new ServiceCall(null);
  const result = await call.Post(`${functionsBaseURL}util`, { data64: url });
  return (result as DataURL).dataURL;
}
