
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as util from "../util";

@Component
export default class DatePicker extends Vue {
  menu = false;
  txtDate = "";
  hasError = false;
  @Prop({ default: "Fecha" }) label!: string;
  @Prop({ default: false }) dark!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: true }) solo!: boolean;
  @Prop({ default: true }) hideDetails!: boolean;
  @Prop({ default: false }) filled!: boolean;
  @Prop({ default: "" }) value!: string;
  @Prop({ default: "primary" }) color!: string;
  @Prop({ default: () => [] }) rules!: util.ValidationRule[];

  @Watch("txtDate")
  changeValue(newValue: string) {
    if (this.value !== newValue) {
      this.$emit("input", newValue);
    }
  }

  @Watch("value")
  onValue(newValue: string) {
    if (this.txtDate !== newValue) {
      this.txtDate = newValue;
    }
  }

  get computedDateFormatted() {
    if (!this.txtDate) {
      return "";
    }
    return this.formatDate(this.txtDate);
  }

  formatDate(date: string) {
    return util.parseDate(date, "YYYY-MM-DD").format("DD/MM/YYYY");
  }

  created() {
    this.txtDate = this.value;
  }

  get localRules() {
    return util.rulesRunner(this);
  }
}
