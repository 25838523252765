
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DatePicker from "../components/DatePicker.vue";
import { CustomFormField, SelectItem } from "../domain/types";
import { VueLanguageMixin } from "../lib/language-ui";
import * as util from "../util";
import * as countries from "../domain/countries";

@Component({
  components: { DatePicker }
})
export default class CustomField extends Vue {
  internalValue = "";
  hasError = false;
  @Prop({ default: "" }) value!: string;
  @Prop({ default: "primary" }) color!: string;
  @Prop({ required: true }) field!: CustomFormField;

  @Watch("internalValue")
  changeValue(newValue: string) {
    if (this.value !== newValue) {
      this.$emit("input", newValue);
    }
  }

  @Watch("value")
  onValue(newValue: string) {
    if (this.internalValue !== newValue) {
      this.internalValue = newValue;
    }
  }

  get boolValue(): boolean {
    return this.internalValue === "true";
  }
  set boolValue(val: boolean) {
    this.internalValue = val ? "true" : "false";
  }

  created() {
    this.internalValue = this.value;
  }

  get langCountries() {
    return (this.$root as VueLanguageMixin).langCountries;
  }

  get lang() {
    return (this.$root as VueLanguageMixin).lang;
  }

  get fieldOptions(): SelectItem[] {
    return (this.field.possibleValues || []).map((val) => {
      return { text: val, value: val };
    });
  }

  get fieldOrCountryOptions(): SelectItem[] {
    let result: SelectItem[] = [];
    if (this.field.type === "country_iso2") {
      result = countries.getAlpha2(this.langCountries);
    } else if (this.field.type === "country_iso3") {
      result = countries.getAlpha3(this.langCountries);
    } else {
      result = this.fieldOptions;
    }
    if (!this.field.required) {
      result.unshift({ text: "-", value: "" });
    }
    return result;
  }

  get fieldRules() {
    const rules: util.ValidationRule[] = [];
    if (this.field.required) {
      rules.push(util.rules.required(this.lang.rule_required));
    }
    if (this.field.type === "number") {
      rules.push(util.rules.numeric(this.lang.rule_numeric, true, true));
    }
    if (this.field.maxLength) {
      rules.push(
        util.rules.length(
          this.lang.rule_maxLength.replace("%0", this.field.maxLength.toString()),
          0,
          this.field.maxLength
        )
      );
    }
    return rules;
  }
}
