
import { Component, Vue } from "vue-property-decorator";
import { Envelope, RedirectResponse } from "./bizumTypes";
import { submitForm } from "../../util/html";

@Component
export default class TxnProcessBizumForm extends Vue {
  envelope: Envelope = {
    Ds_MerchantParameters: "",
    Ds_SignatureVersion: "",
    Ds_Signature: ""
  };
  bizumRedsysRedirectUrl: null | string = null;

  submitRedirectForm(bizumFormData: RedirectResponse) {
    this.envelope = bizumFormData.redirectData.envelope;
    this.bizumRedsysRedirectUrl = bizumFormData.redirectData.redsysUrl;
    if (this.envelope && this.bizumRedsysRedirectUrl) submitForm(this.$refs.formBizum as HTMLFormElement, 500);
  }
}
