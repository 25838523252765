import { FontStyle, PDFWriter } from "../util/pdf";
import { urlToDataURL } from "./backend";
import { Payment } from "./types";
import * as util from "../util";
import * as format from "../util/format";
import { cardBrand, toUpper } from "./filters";
import * as paymentUtil from "./paymentUtil";
import { LangData } from "@pay/lib/language";

export async function downloadReceiptPDF(lang: LangData, pay: Partial<Payment>): Promise<void> {
  if (!pay || !pay.company || !pay.user) {
    util.logError("Invalid payment data");
    throw new Error(lang.pdfError);
  }
  const isSuccess = paymentUtil.isSuccess(pay);
  if (!pay.lastResult || !isSuccess) {
    util.logError("Payment was not payed yet, can't generate receipt");
    throw new Error(lang.pdfError);
  }
  const cpy = pay.company;
  const user = pay.user;
  const writer = new PDFWriter();
  const isBizum =
    pay.lastResult && pay.lastResult?.paymentMethod && pay.lastResult?.paymentMethod === "bizum" ? true : false;
  if (cpy.roundLogoURL) {
    const dataImage = await urlToDataURL(cpy.roundLogoURL);
    writer.image(dataImage, "center", 0);
  }
  // Title
  writer.font({ fontSize: 22 });
  writer.text(cpy.name, "center", 0);
  writer.verticalPad(3);
  // Subheader
  writer.font({ fontSize: 15, color: writer.monoColor(150) });
  writer.text(`${lang.sent_to} ${user.fullName || user.email}`, "center", 0);
  writer.text(`${lang.operation}${pay.clientID}`, "center", 0);
  writer.verticalPad(7);
  // Columns
  const headStyle: FontStyle = {
    style: "normal",
    color: writer.monoColor(100),
    fontSize: 14
  };
  const valueStyle: FontStyle = {
    style: "normal",
    color: writer.monoColor(50),
    fontSize: 18
  };
  writer.fontDefault();
  writer.columnsStart(3);
  const items = [];
  if (pay.opType === "token") {
    items.push([toUpper(lang.resPrice), lang.tokenAmount]);
  } else {
    items.push([toUpper(lang.resPrice), toUpper(pay.currency) + " " + format.printCents(pay.amountCents)]);
  }
  items.push([toUpper(lang.resDate), format.printDate(pay.lastResult?.resultOn, "YYYY-MM-DD")]);

  items.push([
    toUpper(lang.resMethod),
    (isBizum ? "Bizum" : cardBrand(lang, pay.lastResult)) + " - " + pay.lastResult?.cardLastDigits
  ]);
  for (let idx = 0; idx < items.length; idx++) {
    writer.setColumn(idx);
    writer.text(items[idx][0], "center", 0, headStyle);
    writer.text(items[idx][1], "center", 0, valueStyle);
  }
  writer.columnsEnd();
  writer.verticalPad(7);
  // More information
  writer.fontDefault();
  writer.font({ color: writer.monoColor(100), fontSize: 15 });
  writer.text(`${lang.resReference}: ${pay.lastResult?.txnKey}`, "center", 0);
  if (!isBizum) writer.text(`${lang.resPucAuth}: ${pay.lastResult?.pucAuth}`, "center", 0);
  writer.verticalPad(10);
  // Details table
  if (pay.details && pay.details.length > 0) {
    writer.fontDefault();
    writer.font({ style: "bold", fontSize: 14 });
    writer.columnsStart(2);
    writer.setColumn(0);
    writer.text(lang.col_description, "left", 0);
    writer.setColumn(1);
    writer.text(lang.col_price, "right", 0);
    writer.columnsEnd();
    writer.font({ style: "normal" });
    for (const det of pay.details) {
      writer.columnsStart(2);
      writer.setColumn(0);
      writer.text(det.description, "left", 0);
      writer.setColumn(1);
      writer.text(format.printCents(det.amountCents), "right", 0);
      writer.columnsEnd();
    }
    writer.verticalPad(8);
  }
  // Contact
  writer.hr();
  writer.verticalPad(3);
  const contact = `${lang.footer_start} ${cpy.name} ${lang.footer_email}\n${cpy.contactEmail} ${lang.footer_or} ${lang.footer_call} ${cpy.contactPhone}.`;
  writer.fontDefault();
  writer.text(contact, "left", 0, {
    fontSize: 13,
    color: writer.monoColor(150)
  });
  writer.verticalPad(3);
  // Signature
  writer.hr();
  writer.fontDefault();
  writer.font({ fontSize: 12, color: writer.monoColor(175) });
  writer.text(lang.powered_by, "center", 0);
  // writer.text(`${cpy.name} ${lang.page_footer_msg}`, 'center', 0);
  writer.save(`Recibo ${pay.id}.pdf`);
}
