interface LangCountries {
  [key: string]: string;
}

export default {
  AF: "Афганистан",
  AL: "Албания",
  DZ: "Алжир",
  AD: "Андорра",
  AO: "Ангола",
  AG: "Антигуа и Барбуда",
  AR: "Аргентина",
  AM: "Армения",
  AU: "Австралия",
  AT: "Австрия",
  AZ: "Азербайджан",
  BS: "Багамские Острова",
  BH: "Бахрейн",
  BD: "Бангладеш",
  BB: "Барбадос",
  BY: "Белоруссия",
  BE: "Бельгия",
  BZ: "Белиз",
  BJ: "Бенин",
  BT: "Бутан",
  BO: "Боливия",
  BA: "Босния и Герцеговина",
  BW: "Ботсвана",
  BR: "Бразилия",
  BN: "Бруней",
  BG: "Болгария",
  BF: "Буркина-Фасо",
  BI: "Бурунди",
  KH: "Камбоджа",
  CM: "Камерун",
  CA: "Канада",
  CV: "Кабо-Верде",
  CF: "ЦАР",
  TD: "Чад",
  CL: "Чили",
  CN: "Китай (Китайская Народная Республика)",
  CO: "Колумбия",
  KM: "Коморы",
  CG: "Республика Конго",
  CD: "ДР Конго",
  CR: "Коста-Рика",
  CI: "Кот-д’Ивуар",
  HR: "Хорватия",
  CU: "Куба",
  CY: "Кипр",
  CZ: "Чехия",
  DK: "Дания",
  DJ: "Джибути",
  DM: "Доминика",
  DO: "Доминиканская Республика",
  EC: "Эквадор",
  EG: "Египет",
  SV: "Сальвадор",
  GQ: "Экваториальная Гвинея",
  ER: "Эритрея",
  EE: "Эстония",
  ET: "Эфиопия",
  FJ: "Фиджи",
  FI: "Финляндия",
  FR: "Франция",
  GA: "Габон",
  GM: "Гамбия",
  GE: "Грузия",
  DE: "Германия",
  GH: "Гана",
  GR: "Греция",
  GD: "Гренада",
  GT: "Гватемала",
  GN: "Гвинея",
  GW: "Гвинея-Бисау",
  GY: "Гайана",
  HT: "Гаити",
  HN: "Гондурас",
  HU: "Венгрия",
  IS: "Исландия",
  IN: "Индия",
  ID: "Индонезия",
  IR: "Иран",
  IQ: "Ирак",
  IE: "Ирландия",
  IL: "Израиль",
  IT: "Италия",
  JM: "Ямайка",
  JP: "Япония",
  JO: "Иордания",
  KZ: "Казахстан",
  KE: "Кения",
  KI: "Кирибати",
  KP: "КНДР (Корейская Народно-Демократическая Республика)",
  KR: "Республика Корея",
  KW: "Кувейт",
  KG: "Киргизия",
  LA: "Лаос",
  LV: "Латвия",
  LB: "Ливан",
  LS: "Лесото",
  LR: "Либерия",
  LY: "Ливия",
  LI: "Лихтенштейн",
  LT: "Литва",
  LU: "Люксембург",
  MK: "Северная Македония",
  MG: "Мадагаскар",
  MW: "Малави",
  MY: "Малайзия",
  MV: "Мальдивы",
  ML: "Мали",
  MT: "Мальта",
  MH: "Маршалловы Острова",
  MR: "Мавритания",
  MU: "Маврикий",
  MX: "Мексика",
  FM: "Микронезия",
  MA: "Марокко",
  MD: "Молдавия",
  MC: "Монако",
  MN: "Монголия",
  ME: "Черногория",
  MZ: "Мозамбик",
  MM: "Мьянма",
  NA: "Намибия",
  NR: "Науру",
  NP: "Непал",
  NL: "Нидерланды",
  NZ: "Новая Зеландия",
  NI: "Никарагуа",
  NE: "Нигер",
  NG: "Нигерия",
  NO: "Норвегия",
  OM: "Оман",
  PK: "Пакистан",
  PW: "Палау",
  PA: "Панама",
  PG: "Папуа — Новая Гвинея",
  PY: "Парагвай",
  PE: "Перу",
  PH: "Филиппины",
  PL: "Польша",
  PT: "Португалия",
  QA: "Катар",
  RO: "Румыния",
  RU: "Россия",
  RW: "Руанда",
  KN: "Сент-Китс и Невис",
  LC: "Сент-Люсия",
  VC: "Сент-Винсент и Гренадины",
  WS: "Самоа",
  SM: "Сан-Марино",
  ST: "Сан-Томе и Принсипи",
  SA: "Саудовская Аравия",
  SN: "Сенегал",
  RS: "Сербия",
  SC: "Сейшельские Острова",
  SL: "Сьерра-Леоне",
  SG: "Сингапур",
  SK: "Словакия",
  SI: "Словения",
  SB: "Соломоновы Острова",
  SO: "Сомали",
  ZA: "ЮАР",
  SS: "Южный Судан",
  ES: "Испания",
  LK: "Шри-Ланка",
  SD: "Судан",
  SR: "Суринам",
  SZ: "Эсватини",
  SE: "Швеция",
  CH: "Швейцария",
  SY: "Сирия",
  TJ: "Таджикистан",
  TZ: "Танзания",
  TH: "Таиланд",
  TL: "Восточный Тимор",
  TG: "Того",
  TO: "Тонга",
  TT: "Тринидад и Тобаго",
  TN: "Тунис",
  TR: "Турция",
  TM: "Туркмения",
  TV: "Тувалу",
  UG: "Уганда",
  UA: "Украина",
  AE: "ОАЭ",
  GB: "Великобритания",
  US: "США",
  UY: "Уругвай",
  UZ: "Узбекистан",
  VU: "Вануату",
  VE: "Венесуэла",
  VN: "Вьетнам",
  YE: "Йемен",
  ZM: "Замбия",
  ZW: "Зимбабве"
} as LangCountries;
