import { LangData } from "../types";

const lang: LangData = {
  request_from: "Solicitud de",
  sent_to: "Enviado a",
  operation: "Operación",
  due_on: "expira el",
  price_msg: "Por favor completa tus datos a continuación. Tu banco pedirá que valides tu identidad antes de pagar.",
  btn_pay: "Pago Seguro",
  btn_goBack: "Volver",
  btn_goToOther: "Usar otro método de pago",
  btn_showAttachments: "Ver documentos adjuntos",
  btn_retry: "Reintentar",
  btn_downloadPDF: "Descargar PDF",
  rule_required: "Campo obligatorio",
  rule_maxLength: "Largo máximo %0 caracteres",
  rule_numeric: "Este campo solo admite números",
  pdfError: "Se produjo un error al crear el PDF",
  payCOF: "Tarjeta:",
  payOther: "Otra tarjeta",
  fPANLabel: "Número de tarjeta",
  fExpireLabel: "MM / AA",
  fSecurityCode: "CVC",
  loadingPaymentTitle: "Espere un momento",
  loadingPayment: "Cargando información...",
  redirecting: "Espere un momento",
  canceledTitle: "Solicitud cancelada",
  canceledMessage: "Esta solicitud de pago fue cancelada por su creador.",
  expiredTitle: "Solicitud de pago expirada.",
  expiredMessage:
    "Ha pasado el tiempo máximo estipulado para el pago, puedes solicitar una renovación si esto no es correcto.",
  rejectedMessage: "La última operación fue denegada por el banco emisor, verifica los datos y prueba nuevamente.",
  resReference: "Número de referencia",
  resDate: "Fecha de pago",
  resPrice: "Importe",
  resMethod: "Método de pago",
  resPucAuth: "Código de Autorización",
  resMethodDefault: "Tarjeta",
  resMessageTitle: "¡Gracias! Pago exitoso.",
  resMessageSubtitle:
    "Haz finalizado el proceso de pago, en breve recibirás un correo electrónico con los detalles de la transacción.",
  errCardRequired: "Ingrese el número de tarjeta",
  errCardInvalid: "Número de tarjeta inválido",
  errSecurityCodeRequired: "Número de seguridad necesario",
  errSecurityCodeInvalid: "Código inválido",
  errExpirationRequired: "Fecha de expiración necesaria",
  errExpirationInvalid: "La expiración debe ser MM / AA",
  errLoadTitle: "Problema cargando solicitud",
  errLoad1: "Encontramos un error inesperado mientras cargábamos la solicitud de pago.",
  errLoad2: "Prueba refrescar la página en unos minutos.",
  errLoad3:
    "En caso de que el problema persista revisa que el enlace de pago sea correcto o contacta con el comercio que te lo envió.",
  errPreparing: "No se pudo enviar el pago, por favor verifique los datos.",
  col_description: "Descripción",
  col_price: "Importe",
  tbl_total: "Total",
  att_title: "Documentos adjuntos",
  att_download: "Descargar",
  footer_start: "Si tienes preguntas, ponte en contacto con",
  footer_call: "llamando al",
  footer_or: "o",
  footer_email: "por correo a",
  powered_by: "Powered by PAYTEF",
  page_footer_msg: "utiliza PAYTEF para realizar el procesamiento seguro de transacciones.",

  errCardNameRequired: "Campo obligatorio",
  fCardNameLabel: "Nombre del titular",
  fCardNamePlaceholder: "-",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fExpirePlaceholder: "__/__",
  fPANPlaceholder: "···· ···· ···· ····",
  ciCardNameTitle: "Nombre del titular",
  ciCardNumberTitle: "Número de tarjeta",
  ciExpireLabel1: "hasta",
  ciExpireLabel2: "",
  ciExpireTitle: "mes/año",
  ciSecurityCodeTitle: "Cód. Verificación",
  tokenSubtitle: "Finalizado el proceso de tokenización.",
  tokenTitle: "¡Gracias! Tokenización exitosa.",
  tokenAmount: "Tokenización",

  // BIZUM
  txnPaymentMethods: "MEDIOS DE PAGO",
  txnInvalidPhone: "El teléfono seleccionado no es valido.",
  txnInvalidPhoneLength: "La longitud del teléfono seleccionado no es valida.",
  txnRequiredField: "Campo obligatorio.",
  txnPhone: "Teléfono",
  txnPhoneExample: "Ej: ( + 34 ) 700 000 000",
  txnPaymentMethod: "MEDIO DE PAGO",
  btnPay: "Pago Seguro",
  bizum_msg:
    "Por favor completa tu número de teléfono a continuación. Bizum pedirá que valides tu identidad antes de pagar."
};

export default lang;
