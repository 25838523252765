import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const ISO_FORMAT = "YYYY-MM-DD";
export const ISO_DT_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function parseDate(timestamp: string, format: string = ISO_FORMAT): dayjs.Dayjs {
  return dayjs(timestamp, { format });
}

export function parseStDateTime(timestamp: number): dayjs.Dayjs {
  const secondsInDay = timestamp % 86400;
  const secondsInHour = secondsInDay % 3600;
  const absoluteDay = Math.floor(timestamp / 86400);
  const hour = Math.floor(secondsInDay / 3600);
  const minute = Math.floor(secondsInHour / 60);
  const second = secondsInHour % 60;
  return dayjs(new Date(1901, 0, 1, hour, minute, second)).add(absoluteDay, "day");
}

export function isoDateString(offsetDays = 0): string {
  return dayjs().add(offsetDays, "day").format(ISO_FORMAT);
}

export function fileDateTimeString(): string {
  return dayjs().format("DD-MM-YYYY HHmmss");
}

export function parseISODate(timestamp: string): dayjs.Dayjs {
  return parseDate(timestamp, ISO_FORMAT);
}

export function addDays(date: Date, days: number) {
  const result = new Date(date.getTime());
  result.setDate(result.getDate() + days);
  return result;
}

export function toDateString(date: dayjs.Dayjs, dFormat: string = DATE_FORMAT): string {
  return date.format(dFormat);
}

export function toDateTimeString(date: dayjs.Dayjs, dFormat: string = DATETIME_FORMAT): string {
  return date.format(dFormat);
}

export function displayISODate(isoDate: string, dFormat: string = DATE_FORMAT): string {
  return toDateString(parseDate(isoDate, ISO_FORMAT), dFormat);
}

export function today(): dayjs.Dayjs {
  return dayjs();
}

export function now(): dayjs.Dayjs {
  return dayjs();
}

export function isoUtcToLocal(utcTime: string, outputFormat = ISO_DT_FORMAT, inputFormat = ISO_DT_FORMAT): string {
  return dayjs.utc(utcTime, inputFormat).local().format(outputFormat);
}
