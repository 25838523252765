import { LangData } from "../types";

const lang: LangData = {
  request_from: "Request from",
  sent_to: "Sent to",
  operation: "Operation",
  due_on: "due on",
  price_msg: "Please fill your information below. You'll be asked to verify your identity with your bank.",
  btn_pay: "Secure Pay",
  btn_goBack: "Go back",
  btn_goToOther: "Use a different payment method",
  btn_showAttachments: "See attachments",
  btn_retry: "Retry",
  btn_downloadPDF: "Download PDF",
  rule_required: "Required",
  rule_maxLength: "Maximum length is %0",
  rule_numeric: "Only numbers allowed",
  pdfError: "An error was encountered while creating the PDF",
  payCOF: "Card:",
  payOther: "Another card",
  fPANLabel: "Card number",
  fExpireLabel: "MM / YY",
  fSecurityCode: "CVC",
  loadingPaymentTitle: "Please wait a moment",
  loadingPayment: "Loading information...",
  redirecting: "Wait for a moment",
  canceledTitle: "Payment request canceled",
  canceledMessage: "This request was canceled by it's creator.",
  expiredTitle: "Payment request expired.",
  expiredMessage: "The due date for payment has already passed, you can contact it's creator to know more.",
  rejectedMessage: "Last payment attempt was denied by the issuing bank, please verify your information and try again.",
  resReference: "Reference number",
  resDate: "Date paid",
  resPrice: "Amount paid",
  resMethod: "Payment method",
  resPucAuth: "Authorization code",
  resMethodDefault: "Card",
  resMessageTitle: "Thanks! You're all set",
  resMessageSubtitle:
    "The payment process was finished successfully. In a moment you will receive an email with the transaction details.",
  errCardRequired: "Enter the card number",
  errCardInvalid: "Card number invalid or not supported",
  errSecurityCodeRequired: "Security code is needed",
  errSecurityCodeInvalid: "Invalid code",
  errExpirationRequired: "Expiration date is necessary",
  errExpirationInvalid: "Expiration must be MM / YY",
  errLoadTitle: "Problem loading the request",
  errLoad1: "We found an unexpected error while loading this payment request.",
  errLoad2: "Try refreshing the page in a few minutes.",
  errLoad3:
    "If the problem persists check that the payment link is correct or contact the business that sent you the link.",
  errPreparing: "Payment couldn't be sent, please check the information for errors.",
  col_description: "Description",
  col_price: "Amount",
  tbl_total: "Total",
  att_title: "Attachments",
  att_download: "Download",
  footer_start: "If you have any questions please contact",
  footer_call: "by calling",
  footer_or: "or",
  footer_email: "via email to",
  powered_by: "Powered by PAYTEF",
  page_footer_msg: "uses PAYTEF to provide secure payment processing.",

  errCardNameRequired: "Mandatory field",
  fCardNameLabel: "Cardholder's name",
  fCardNamePlaceholder: "-",
  fCvvLabel: "Security code",
  fCvvPlaceholder: "XXX",
  fExpirePlaceholder: "__/__",
  fPANPlaceholder: "···· ···· ···· ····",
  ciCardNameTitle: "Cardholder's name",
  ciCardNumberTitle: "Card number",
  ciExpireLabel1: "valid",
  ciExpireLabel2: "until",
  ciExpireTitle: "month/year",
  ciSecurityCodeTitle: "Security code",
  tokenSubtitle: "The card token was generated successfully.",
  tokenTitle: "Thanks! Tokenization success.",
  tokenAmount: "Tokenization",

  // BIZUM
  txnPaymentMethods: "PAYMENT METHODS",
  txnInvalidPhone: "The selected phone is not valid.",
  txnInvalidPhoneLength: "The length of the selected phone is not valid.",
  txnRequiredField: "Obligatory field.",
  txnPhone: "Phone",
  txnPhoneExample: "Ex: ( + 34 ) 700 000 000",
  txnPaymentMethod: "PAYMENT METHOD",
  btnPay: "Secure payment",
  bizum_msg: "Please fill in your phone number below. Bizum will ask you to validate your identity before paying."
};

export default lang;
