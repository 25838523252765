import { LangData } from "../types";

const lang: LangData = {
  request_from: "Demande de",
  sent_to: "Envoyé à",
  operation: "Opération",
  due_on: "expire le",
  price_msg:
    "Remplissez vos informations ci-dessous. Votre banque vous demandera de valider votre identité avant de payer.",
  btn_pay: "Paiement sécurisé",
  btn_goBack: "Revenir",
  btn_goToOther: "Utiliser un autre moyen de paiement",
  btn_showAttachments: "Voir documents joints",
  btn_retry: "Réessayez",
  btn_downloadPDF: "Télécharger le PDF",
  rule_required: "Champ requis",
  rule_maxLength: "Longueur maximale %0 caractères",
  rule_numeric: "Ce champ ne prend en charge que les chiffres",
  pdfError: "Une erreur s'est produite lors de la création du PDF",
  payCOF: "Carte:",
  payOther: "Une autre carte",
  fPANLabel: "Numéro de carte",
  fExpireLabel: "MM / AA",
  fSecurityCode: "CVC",
  loadingPaymentTitle: "Attendez un moment",
  loadingPayment: "Chargement des informations...",
  redirecting: "Attendez un moment",
  canceledTitle: "Demande annulée",
  canceledMessage: "Cette demande de paiement a été annulée par son créateur.",
  expiredTitle: "La demande de paiement a expiré.",
  expiredMessage:
    "Le délai maximum stipulé pour le paiement est dépassé, vous pouvez demander un renouvellement si ce n'est pas correct.",
  rejectedMessage: "La dernière opération a été refusée par la banque émettrice, vérifiez les données et réessayez.",
  resReference: "Référence",
  resDate: "Date de paiement",
  resPrice: "Montant",
  resMethod: "Procédé de paiement",
  resPucAuth: "Code d'autorisation",
  resMethodDefault: "Carte",
  resMessageTitle: "Merci! Paiement réussi.",
  resMessageSubtitle:
    "Vous avez terminé le processus de paiement, vous recevrez sous peu un e-mail avec les détails de la transaction.",
  errCardRequired: "Entrez le numéro de la carte",
  errCardInvalid: "Numéro de carte invalide",
  errSecurityCodeRequired: "Numéro de sécurité requis",
  errSecurityCodeInvalid: "Code invalide",
  errExpirationRequired: "Date d'expiration requise",
  errExpirationInvalid: "L'expiration doit être MM/AA",
  errLoadTitle: "Problème de chargement de la demande",
  errLoad1: "Nous avons rencontré une erreur inattendue lors du téléchargement de la demande de paiement.",
  errLoad2: "Essayez d'actualiser la page dans quelques minutes.",
  errLoad3:
    "Si le problème persiste, vérifiez que le lien de paiement est correct ou contactez l'entreprise qui vous l'a envoyé.",
  errPreparing: "Le paiement n'a pas pu être envoyé, veuillez vérifier les données.",
  col_description: "Description",
  col_price: "Montant",
  tbl_total: "Total",
  att_title: "Pièces jointes",
  att_download: "Télécharger",
  footer_start: "Si vous avez des questions, contactez",
  footer_call: "en appelant le",
  footer_or: "ou",
  footer_email: "par email à",
  powered_by: "Powered by PAYTEF",
  page_footer_msg: "utilise PAYTEF pour effectuer le traitement sécurisé des transactions.",

  errCardNameRequired: "Champ obligatoire",
  fCardNameLabel: "Nom du titulaire ",
  fCardNamePlaceholder: "-",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fExpirePlaceholder: "__/__",
  fPANPlaceholder: "···· ···· ···· ····",
  ciCardNameTitle: "Nom du titulaire",
  ciCardNumberTitle: "Numéro de carte",
  ciExpireLabel1: "jusqu'à",
  ciExpireLabel2: "",
  ciExpireTitle: "mois/année",
  ciSecurityCodeTitle: "Code de vérification",
  tokenSubtitle: "Processus de tokenisation terminé.",
  tokenTitle: "Merci! Tokénisation réussie.",
  tokenAmount: "Tokenisation",

  // BIZUM
  txnPaymentMethods: "MÉTHODES DE PAYEMENT",
  txnInvalidPhone: "Le téléphone sélectionné n'est pas valide.",
  txnInvalidPhoneLength: "La longueur du téléphone sélectionné n'est pas valide.",
  txnRequiredField: "Champ requis.",
  txnPhone: "Téléphone",
  txnPhoneExample: "Ex : ( + 34 ) 700 000 000",
  txnPaymentMethod: "MOYEN DE PAIEMENT",
  btnPay: "Paiement sécurisé",
  bizum_msg:
    "Veuillez saisir votre numéro de téléphone ci-dessous. Bizum vous demandera de valider votre identité avant de payer."
};

export default lang;
