import * as debug from "./util/debug";

const prodBaseURL = "https://europe-west1-paytef-web.cloudfunctions.net/api/";

let functionsBaseURL = prodBaseURL;
if (debug.isLocalhost()) {
  functionsBaseURL = "http://localhost:5011/paytef-web/europe-west1/api/";
} else if (debug.isDevSite()) {
  functionsBaseURL = "https://europe-west1-paytef-web.cloudfunctions.net/apiDev/";
}

export default {
  functionsBaseURL
};
