export function submitForm(form: HTMLFormElement, submitDelayMS: number) {
  setTimeout(
    () => {
      if (submitDelayMS > 0) {
        form.submit();
      } else {
        alert("Esta página no será redirigida porque es de prueba. Presione atrás para ver otra página.");
      }
    },
    submitDelayMS < 0 ? 5000 : submitDelayMS
  );
}

export function redirectToUrl(redirectURL: string) {
  if (window.location.replace) {
    window.location.replace(redirectURL);
  } else {
    window.location.href = redirectURL;
  }
}
