//import countries from "./strings/countries.json";

import { CountryDir, LangCountries } from "./types";
import countries from "./dataLoader";
import { LangKey } from "../language/types";

export function getLangCountries(langName: LangKey): LangCountries {
  const names = countries as unknown as CountryDir; // Hack to read it as dictionary
  const data = names[langName];
  if (!data) return names["EN"] || {};
  return data;
}
