
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "./css/flags.scss";
import { VueLanguageMixin, Language } from "./types";
import { LangData } from "../language";

const DEFAULT_LANG = "ES";

@Component
export default class LangBar extends Vue {
  @Prop({ default: DEFAULT_LANG }) langIdx!: string;
  currentLang = DEFAULT_LANG;

  get vueRoot(): VueLanguageMixin {
    return this.$root as VueLanguageMixin;
  }

  get lang(): LangData {
    return this.vueRoot.lang;
  }

  get langDef(): Partial<Language> {
    return this.vueRoot.langDef;
  }

  get langOptions(): Language[] {
    return this.vueRoot.languages;
  }

  created() {
    this.onLangChanged(this.langIdx);
  }

  setRootLang(lang: string) {
    this.vueRoot.changeLang(lang);
  }

  @Watch("langIdx")
  onLangChanged(newValue: string): void {
    this.currentLang = this.toUpper(newValue || DEFAULT_LANG);
  }

  @Watch("currentLang")
  onCurrentLangChanged(newValue: string): void {
    this.setRootLang(newValue);
    this.$emit("change", newValue);
  }

  toUpper(val?: string): string {
    if (!val) {
      return "";
    }
    return val.toString().toUpperCase();
  }
}
