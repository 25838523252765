import { LangKey, LangData, LangKeyLike, LangDic, GroupedLangData } from "./types";
import { allGroupedStrings, allStrings, languageNames } from "./dataLoader";

export * from "./types";

function detectAppLanguage(): LangKey {
  try {
    let lang = "";
    if (typeof navigator !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      lang = navigator.language || ((navigator as any).userLanguage as string);
      if (!lang && navigator.languages && navigator.languages.length > 0) {
        lang = navigator.languages[0];
      }
    }
    const parts = lang.split("-");
    return getValidLang(parts[0] || "");
  } catch (err) {
    console.error("Error detecting language", err);
    return "EN";
  }
}

export const defaultLangKey: LangKey = detectAppLanguage();

export function toLangKey(langKey?: LangKeyLike): LangKey {
  if (!langKey) return defaultLangKey;
  return langKey as LangKey;
}

function fillStrings(langs: LangDic, langKey?: LangKeyLike, defaultKey?: LangKeyLike): LangData {
  const data = langs[toLangKey(langKey)];
  const defaultData = langs[toLangKey(defaultKey)];
  if (!data) return defaultData || {};
  return {
    ...defaultData,
    ...data
  };
}

function getValidLang(lang: string): LangKey {
  lang = lang.toLocaleUpperCase();
  let selectedLang = "EN";
  if (lang.length > 0) {
    getLanguageNames().map((languaje: { key: LangKey }) => {
      if (lang === languaje.key) selectedLang = languaje.key;
    });
  }
  if (lang === "CT" || lang === "VL" || lang === "EU" || lang === "GA") selectedLang = "ES";
  return selectedLang as LangKey;
}

export function getLanguageNames() {
  return languageNames;
}

export function getLanguages(): LangKey[] {
  return getLanguageNames().map((l) => l.key);
}

export function getStrings(langKey?: LangKeyLike, keepEmpty = false): LangData {
  if (!keepEmpty) {
    return fillStrings(allStrings, langKey);
  } else {
    return allStrings[toLangKey(langKey)] || {};
  }
}

export function getGroupedStrings(langKey?: LangKeyLike): GroupedLangData {
  const key = toLangKey(langKey);
  return allGroupedStrings[key] || allGroupedStrings[defaultLangKey] || {};
}
