
import { Component, Vue, Prop } from "vue-property-decorator";
// import * as util from "@/util/validation";
import * as util from "../../util/validation";
import { VueLanguageMixin } from "@pay/lib/language-ui";

type VForm = Vue & { validate: () => boolean };

@Component
export default class CustomPhoneInputs extends Vue {
  @Prop({ default: "" }) value!: string;
  input = this.$refs.phone as HTMLInputElement;
  maxLength = 11;
  inputValue = "( + 34) ";

  get vueRoot(): VueLanguageMixin {
    return this.$root as VueLanguageMixin;
  }

  get lang() {
    return this.vueRoot.lang;
  }

  //Format number value
  formatValue(value: string) {
    const onlyNumber = value.replace(/\D/g, "").substring(0, 11);
    if (onlyNumber.length > this.maxLength) return;
    let term1 = "";
    if (onlyNumber.length > 0) term1 = " + " + onlyNumber.substring(0, 2);
    if (onlyNumber.length > 2) term1 = "(" + term1 + ")";
    const term2 = onlyNumber.length > 2 ? " " + onlyNumber.substring(2, 5) : "";
    const term3 = onlyNumber.length > 5 ? " " + onlyNumber.substring(5, 8) : "";
    const term4 = onlyNumber.length > 8 ? " " + onlyNumber.substring(8, 11) : "";
    this.inputValue = term1 + term2 + term3 + term4;
  }

  // Only Allow number input
  checkNumberInput(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;
    if (target.value.replace(/\D/g, "").length === 5) return;
    if (e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft" || e.key === "ArrowRight") return;
    if (e.key.replace(/\D/g, "").length) return;
    e.preventDefault();
  }

  // To validate phone manually
  isPhoneInvalid() {
    const phone = this.$refs["phone"] as VForm;
    return !phone.validate();
  }

  // Phone Rules
  get phoneRules() {
    return [
      util.rules.required(this.lang.txnRequiredField),
      util.rules.phone(this.lang.txnInvalidPhone, true),
      (value: string | undefined) => {
        value = value ? value.replace(/\D/g, "") : "";
        let validation = value.length > 5 && value.length < 16 ? true : false;
        return validation || this.lang?.txnInvalidPhoneLength;
      }
    ];
  }
}
