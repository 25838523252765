interface LangCountries {
  [key: string]: string;
}

export default {
  AF: "Afeganistão",
  AL: "Albânia",
  DZ: "Argélia",
  AD: "Andorra",
  AO: "Angola",
  AG: "Antígua e Barbuda",
  AR: "Argentina",
  AM: "Armênia",
  AU: "Austrália",
  AT: "Áustria",
  AZ: "Azerbaijão",
  BS: "Bahamas",
  BH: "Barém",
  BD: "Bangladexe",
  BB: "Barbados",
  BY: "Bielorrússia",
  BE: "Bélgica",
  BZ: "Belize",
  BJ: "Benim",
  BT: "Butão",
  BO: "Bolívia",
  BA: "Bósnia e Herzegovina",
  BW: "Botsuana",
  BR: "Brasil",
  BN: "Brunei",
  BG: "Bulgária",
  BF: "Burquina Fasso",
  BI: "Burundi",
  KH: "Camboja",
  CM: "Camarões",
  CA: "Canadá",
  CV: "Cabo Verde",
  CF: "República Centro-Africana",
  TD: "Chade",
  CL: "Chile",
  CN: "China",
  CO: "Colômbia",
  KM: "Comores",
  CG: "República do Congo",
  CD: "República Democrática do Congo",
  CR: "Costa Rica",
  CI: "Costa do Marfim",
  HR: "Croácia",
  CU: "Cuba",
  CY: "Chipre",
  CZ: "Chéquia",
  DK: "Dinamarca",
  DJ: "Djibuti",
  DM: "Dominica",
  DO: "República Dominicana",
  EC: "Equador",
  EG: "Egito",
  SV: "El Salvador",
  GQ: "Guiné Equatorial",
  ER: "Eritreia",
  EE: "Estónia",
  ET: "Etiópia",
  FJ: "Fiji",
  FI: "Finlândia",
  FR: "França",
  GA: "Gabão",
  GM: "Gâmbia",
  GE: "Geórgia",
  DE: "Alemanha",
  GH: "Gana",
  GR: "Grécia",
  GD: "Granada",
  GT: "Guatemala",
  GN: "Guiné",
  GW: "Guiné-Bissau",
  GY: "Guiana",
  HT: "Haiti",
  HN: "Honduras",
  HU: "Hungria",
  IS: "Islândia",
  IN: "Índia",
  ID: "Indonésia",
  IR: "Irão",
  IQ: "Iraque",
  IE: "Irlanda",
  IL: "Israel",
  IT: "Itália",
  JM: "Jamaica",
  JP: "Japão",
  JO: "Jordânia",
  KZ: "Cazaquistão",
  KE: "Quênia",
  KI: "Quiribáti",
  KP: "Coreia do Norte",
  KR: "Coreia do Sul",
  KW: "Kuwait",
  KG: "Quirguistão",
  LA: "Laos",
  LV: "Letônia",
  LB: "Líbano",
  LS: "Lesoto",
  LR: "Libéria",
  LY: "Líbia",
  LI: "Listenstaine",
  LT: "Lituânia",
  LU: "Luxemburgo",
  MK: "Macedônia do Norte",
  MG: "Madagáscar",
  MW: "Maláui",
  MY: "Malásia",
  MV: "Maldivas",
  ML: "Mali",
  MT: "Malta",
  MH: "Ilhas Marshall",
  MR: "Mauritânia",
  MU: "Maurícia",
  MX: "México",
  FM: "Estados Federados da Micronésia",
  MA: "Marrocos",
  MD: "Moldávia",
  MC: "Mónaco",
  MN: "Mongólia",
  ME: "Montenegro",
  MZ: "Moçambique",
  MM: "Mianmar",
  NA: "Namíbia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Países Baixos",
  NZ: "Nova Zelândia",
  NI: "Nicarágua",
  NE: "Níger",
  NG: "Nigéria",
  NO: "Noruega",
  OM: "Omã",
  PK: "Paquistão",
  PW: "Palau",
  PA: "Panamá",
  PG: "Papua-Nova Guiné",
  PY: "Paraguai",
  PE: "Peru",
  PH: "Filipinas",
  PL: "Polónia",
  PT: "Portugal",
  QA: "Catar",
  RO: "Roménia",
  RU: "Rússia",
  RW: "Ruanda",
  KN: "São Cristóvão e Neves",
  LC: "Santa Lúcia",
  VC: "São Vicente e Granadinas",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé e Príncipe",
  SA: "Arábia Saudita",
  SN: "Senegal",
  RS: "Sérvia",
  SC: "Seicheles",
  SL: "Serra Leoa",
  SG: "Singapura",
  SK: "Eslováquia",
  SI: "Eslovênia",
  SB: "Ilhas Salomão",
  SO: "Somália",
  ZA: "África do Sul",
  SS: "Sudão do Sul",
  ES: "Espanha",
  LK: "Seri Lanca",
  SD: "Sudão",
  SR: "Suriname",
  SZ: "Essuatíni",
  SE: "Suécia",
  CH: "Suíça",
  SY: "Síria",
  TJ: "Tajiquistão",
  TZ: "Tanzânia",
  TH: "Tailândia",
  TL: "Timor-Leste",
  TG: "Togo",
  TO: "Tonga",
  TT: "Trinidad e Tobago",
  TN: "Tunísia",
  TR: "Turquia",
  TM: "Turcomenistão",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ucrânia",
  AE: "Emirados Árabes Unidos",
  GB: "Reino Unido",
  US: "Estados Unidos",
  UY: "Uruguai",
  UZ: "Uzbequistão",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnã",
  YE: "Iêmen",
  ZM: "Zâmbia",
  ZW: "Zimbábue"
} as LangCountries;
