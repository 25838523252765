import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/src/locale/es";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueTheMask = require("vue-the-mask");
Vue.use(VueTheMask.default);

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md"
  },
  theme: {
    dark: false,
    /*options: {
      customProperties: true
    },*/
    themes: {
      light: {
        primary: "#060606",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
        /*
    Default Vuetify colors
      primary: '#1976D2',
      secondary: '#424242',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107'
    */
      }
    }
  },
  lang: {
    locales: { es },
    current: "es"
  }
});
