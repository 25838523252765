import { LangData } from "../types";

const lang: LangData = {
  request_from: "Richiesta di",
  sent_to: "Inviato a",
  operation: "Operazione",
  due_on: "Scade il",
  price_msg:
    "Si prega di compilare le informazioni di seguito. La tua banca ti chiederà di convalidare la tua identità prima di pagare.",
  btn_pay: "Pagamento Sicuro",
  btn_goBack: "Ritorno",
  btn_goToOther: "Usa un altro metodo di pagamento",
  btn_showAttachments: "Vedi documenti allegati",
  btn_retry: "Riprovare",
  btn_downloadPDF: "Scarica il pdf",
  rule_required: "Campo obbligatorio",
  rule_maxLength: "Lunghezza massima %0 caratteri",
  rule_numeric: "Questo campo supporta solo i numeri",
  pdfError: "Si è verificato un errore durante la creazione del PDF",
  payCOF: "Carta:",
  payOther: "Un'altra carta",
  fPANLabel: "Numero di carta",
  fExpireLabel: "MM / AA",
  fSecurityCode: "CVC",
  loadingPaymentTitle: "Aspetta un attimo",
  loadingPayment: "Caricamento informazioni...",
  redirecting: "Aspetta un attimo",
  canceledTitle: "Richiesta annullata",
  canceledMessage: "Questa richiesta di pagamento è stata annullata dal suo creatore.",
  expiredTitle: "Richiesta di pagamento scaduta.",
  expiredMessage:
    "Trascorso il tempo massimo previsto per il pagamento, è possibile richiedere il rinnovo se questo non è corretto.",
  rejectedMessage: "L'ultima operazione è stata negata dalla banca emittente, verificare i dati e riprovare.",
  resReference: "Numero di riferimento",
  resDate: "Data di pagamento",
  resPrice: "Quantità",
  resMethod: "Metodo di pagamento",
  resPucAuth: "Codice di autorizzazione",
  resMethodDefault: "Carta",
  resMessageTitle: "Grazie! Pagamento riuscito.",
  resMessageSubtitle:
    "Hai completato la procedura di pagamento, a breve riceverai un'e-mail con i dettagli della transazione.",
  errCardRequired: "Inserisci il numero della carta",
  errCardInvalid: "Numero di carta non valido",
  errSecurityCodeRequired: "Numero di sicurezza richiesto",
  errSecurityCodeInvalid: "Codice non valido",
  errExpirationRequired: "Data di scadenza richiesta",
  errExpirationInvalid: "La scadenza deve essere MM/AA",
  errLoadTitle: "Problema durante il caricamento della richiesta",
  errLoad1: "Si è verificato un errore imprevisto durante il caricamento della richiesta di pagamento.",
  errLoad2: "Prova ad aggiornare la pagina tra qualche minuto.",
  errLoad3:
    "Se il problema persiste, verifica che il link di pagamento sia corretto o contatta l'azienda che te lo ha inviato.",
  errPreparing: "Non è stato possibile inviare il pagamento, verificare i dati.",
  col_description: "Descrizione",
  col_price: "Quantità",
  tbl_total: "Totale",
  att_title: "Allegati",
  att_download: "Scaricamento",
  footer_start: "In caso di domande, si prega di contattare",
  footer_call: "chiamando il",
  footer_or: "o",
  footer_email: "via email ad",
  powered_by: "Powered by PAYTEF",
  page_footer_msg: "utilizza PAYTEF per eseguire transazioni sicure.",

  errCardNameRequired: "Campo obbligatorio",
  fCardNameLabel: "Nome del titolare",
  fCardNamePlaceholder: "-",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fExpirePlaceholder: "__/__",
  fPANPlaceholder: "···· ···· ···· ····",
  ciCardNameTitle: "Nome del titolare",
  ciCardNumberTitle: "Numero carta",
  ciExpireLabel1: "fino a",
  ciExpireLabel2: "",
  ciExpireTitle: "mese/anno",
  ciSecurityCodeTitle: "Codice di verifica",
  tokenSubtitle: "Completato il processo di tokenizzazione.",
  tokenTitle: "Grazie! tokenizzazione riuscita.",
  tokenAmount: "Tokenizzazione",

  // BIZUM
  txnPaymentMethods: "MODALITÀ DI PAGAMENTO",
  txnInvalidPhone: "Il telefono selezionato non è valido.",
  txnInvalidPhoneLength: "La lunghezza del telefono selezionato non è valida.",
  txnRequiredField: "Campo obbligatorio.",
  txnPhone: "Telefono",
  txnPhoneExample: "Es: ( + 34 ) 700 000 000",
  txnPaymentMethod: "METODO DI PAGAMENTO",
  btnPay: "Pagamento sicuro",
  bizum_msg:
    "Inserisci il tuo numero di telefono qui sotto. Bizum ti chiederà di convalidare la tua identità prima di pagare."
};

export default lang;
