export { shadeColor } from "./color";

export function getPANIcon(panType: string): string {
  switch (panType) {
    case "visa":
      return "fab fa-cc-visa";
    case "mastercard":
      return "fab fa-cc-mastercard";
    case "american-express":
      return "fab fa-cc-amex";
    case "diners-club":
      return "fab fa-cc-diners-club";
    case "discover":
      return "fab fa-cc-discover";
    case "jcb":
      return "fab fa-cc-jcb";
    default:
      return "fa-credit-card";
  }
}

export function makePANMask(gaps: number[], maxLength: number): string {
  const allGaps = [...gaps, maxLength];
  let mask = "";
  let lastIdx = 0;
  for (let i = 0; i < allGaps.length; i++) {
    const idx = allGaps[i];
    for (let j = 0; j < idx - lastIdx; j++) {
      mask += "#";
    }
    lastIdx = idx;
    if (i !== allGaps.length - 1) {
      mask += " ";
    }
  }
  return mask;
}
