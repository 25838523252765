const codes: { [key: string]: string[] } = {
  AF: ["AF", "AFG", "004", "ISO 3166-2:AF"],
  AL: ["AL", "ALB", "008", "ISO 3166-2:AL"],
  DZ: ["DZ", "DZA", "012", "ISO 3166-2:DZ"],
  AS: ["AS", "ASM", "016", "ISO 3166-2:AS"],
  AD: ["AD", "AND", "020", "ISO 3166-2:AD"],
  AO: ["AO", "AGO", "024", "ISO 3166-2:AO"],
  AI: ["AI", "AIA", "660", "ISO 3166-2:AI"],
  AQ: ["AQ", "ATA", "010", "ISO 3166-2:AQ"],
  AG: ["AG", "ATG", "028", "ISO 3166-2:AG"],
  AR: ["AR", "ARG", "032", "ISO 3166-2:AR"],
  AM: ["AM", "ARM", "051", "ISO 3166-2:AM"],
  AW: ["AW", "ABW", "533", "ISO 3166-2:AW"],
  AU: ["AU", "AUS", "036", "ISO 3166-2:AU"],
  AT: ["AT", "AUT", "040", "ISO 3166-2:AT"],
  AZ: ["AZ", "AZE", "031", "ISO 3166-2:AZ"],
  BS: ["BS", "BHS", "044", "ISO 3166-2:BS"],
  BH: ["BH", "BHR", "048", "ISO 3166-2:BH"],
  BD: ["BD", "BGD", "050", "ISO 3166-2:BD"],
  BB: ["BB", "BRB", "052", "ISO 3166-2:BB"],
  BY: ["BY", "BLR", "112", "ISO 3166-2:BY"],
  BE: ["BE", "BEL", "056", "ISO 3166-2:BE"],
  BZ: ["BZ", "BLZ", "084", "ISO 3166-2:BZ"],
  BJ: ["BJ", "BEN", "204", "ISO 3166-2:BJ"],
  BM: ["BM", "BMU", "060", "ISO 3166-2:BM"],
  BT: ["BT", "BTN", "064", "ISO 3166-2:BT"],
  BO: ["BO", "BOL", "068", "ISO 3166-2:BO"],
  BA: ["BA", "BIH", "070", "ISO 3166-2:BA"],
  BW: ["BW", "BWA", "072", "ISO 3166-2:BW"],
  BV: ["BV", "BVT", "074", "ISO 3166-2:BV"],
  BR: ["BR", "BRA", "076", "ISO 3166-2:BR"],
  IO: ["IO", "IOT", "086", "ISO 3166-2:IO"],
  BN: ["BN", "BRN", "096", "ISO 3166-2:BN"],
  BG: ["BG", "BGR", "100", "ISO 3166-2:BG"],
  BF: ["BF", "BFA", "854", "ISO 3166-2:BF"],
  BI: ["BI", "BDI", "108", "ISO 3166-2:BI"],
  KH: ["KH", "KHM", "116", "ISO 3166-2:KH"],
  CM: ["CM", "CMR", "120", "ISO 3166-2:CM"],
  CA: ["CA", "CAN", "124", "ISO 3166-2:CA"],
  CV: ["CV", "CPV", "132", "ISO 3166-2:CV"],
  KY: ["KY", "CYM", "136", "ISO 3166-2:KY"],
  CF: ["CF", "CAF", "140", "ISO 3166-2:CF"],
  TD: ["TD", "TCD", "148", "ISO 3166-2:TD"],
  CL: ["CL", "CHL", "152", "ISO 3166-2:CL"],
  CN: ["CN", "CHN", "156", "ISO 3166-2:CN"],
  CX: ["CX", "CXR", "162", "ISO 3166-2:CX"],
  CC: ["CC", "CCK", "166", "ISO 3166-2:CC"],
  CO: ["CO", "COL", "170", "ISO 3166-2:CO"],
  KM: ["KM", "COM", "174", "ISO 3166-2:KM"],
  CG: ["CG", "COG", "178", "ISO 3166-2:CG"],
  CD: ["CD", "COD", "180", "ISO 3166-2:CD"],
  CK: ["CK", "COK", "184", "ISO 3166-2:CK"],
  CR: ["CR", "CRI", "188", "ISO 3166-2:CR"],
  CI: ["CI", "CIV", "384", "ISO 3166-2:CI"],
  HR: ["HR", "HRV", "191", "ISO 3166-2:HR"],
  CU: ["CU", "CUB", "192", "ISO 3166-2:CU"],
  CY: ["CY", "CYP", "196", "ISO 3166-2:CY"],
  CZ: ["CZ", "CZE", "203", "ISO 3166-2:CZ"],
  DK: ["DK", "DNK", "208", "ISO 3166-2:DK"],
  DJ: ["DJ", "DJI", "262", "ISO 3166-2:DJ"],
  DM: ["DM", "DMA", "212", "ISO 3166-2:DM"],
  DO: ["DO", "DOM", "214", "ISO 3166-2:DO"],
  EC: ["EC", "ECU", "218", "ISO 3166-2:EC"],
  EG: ["EG", "EGY", "818", "ISO 3166-2:EG"],
  SV: ["SV", "SLV", "222", "ISO 3166-2:SV"],
  GQ: ["GQ", "GNQ", "226", "ISO 3166-2:GQ"],
  ER: ["ER", "ERI", "232", "ISO 3166-2:ER"],
  EE: ["EE", "EST", "233", "ISO 3166-2:EE"],
  ET: ["ET", "ETH", "231", "ISO 3166-2:ET"],
  FK: ["FK", "FLK", "238", "ISO 3166-2:FK"],
  FO: ["FO", "FRO", "234", "ISO 3166-2:FO"],
  FJ: ["FJ", "FJI", "242", "ISO 3166-2:FJ"],
  FI: ["FI", "FIN", "246", "ISO 3166-2:FI"],
  FR: ["FR", "FRA", "250", "ISO 3166-2:FR"],
  GF: ["GF", "GUF", "254", "ISO 3166-2:GF"],
  PF: ["PF", "PYF", "258", "ISO 3166-2:PF"],
  TF: ["TF", "ATF", "260", "ISO 3166-2:TF"],
  GA: ["GA", "GAB", "266", "ISO 3166-2:GA"],
  GM: ["GM", "GMB", "270", "ISO 3166-2:GM"],
  GE: ["GE", "GEO", "268", "ISO 3166-2:GE"],
  DE: ["DE", "DEU", "276", "ISO 3166-2:DE"],
  GH: ["GH", "GHA", "288", "ISO 3166-2:GH"],
  GI: ["GI", "GIB", "292", "ISO 3166-2:GI"],
  GR: ["GR", "GRC", "300", "ISO 3166-2:GR"],
  GL: ["GL", "GRL", "304", "ISO 3166-2:GL"],
  GD: ["GD", "GRD", "308", "ISO 3166-2:GD"],
  GP: ["GP", "GLP", "312", "ISO 3166-2:GP"],
  GU: ["GU", "GUM", "316", "ISO 3166-2:GU"],
  GT: ["GT", "GTM", "320", "ISO 3166-2:GT"],
  GN: ["GN", "GIN", "324", "ISO 3166-2:GN"],
  GW: ["GW", "GNB", "624", "ISO 3166-2:GW"],
  GY: ["GY", "GUY", "328", "ISO 3166-2:GY"],
  HT: ["HT", "HTI", "332", "ISO 3166-2:HT"],
  HM: ["HM", "HMD", "334", "ISO 3166-2:HM"],
  VA: ["VA", "VAT", "336", "ISO 3166-2:VA"],
  HN: ["HN", "HND", "340", "ISO 3166-2:HN"],
  HK: ["HK", "HKG", "344", "ISO 3166-2:HK"],
  HU: ["HU", "HUN", "348", "ISO 3166-2:HU"],
  IS: ["IS", "ISL", "352", "ISO 3166-2:IS"],
  IN: ["IN", "IND", "356", "ISO 3166-2:IN"],
  ID: ["ID", "IDN", "360", "ISO 3166-2:ID"],
  IR: ["IR", "IRN", "364", "ISO 3166-2:IR"],
  IQ: ["IQ", "IRQ", "368", "ISO 3166-2:IQ"],
  IE: ["IE", "IRL", "372", "ISO 3166-2:IE"],
  IL: ["IL", "ISR", "376", "ISO 3166-2:IL"],
  IT: ["IT", "ITA", "380", "ISO 3166-2:IT"],
  JM: ["JM", "JAM", "388", "ISO 3166-2:JM"],
  JP: ["JP", "JPN", "392", "ISO 3166-2:JP"],
  JO: ["JO", "JOR", "400", "ISO 3166-2:JO"],
  KZ: ["KZ", "KAZ", "398", "ISO 3166-2:KZ"],
  KE: ["KE", "KEN", "404", "ISO 3166-2:KE"],
  KI: ["KI", "KIR", "296", "ISO 3166-2:KI"],
  KP: ["KP", "PRK", "408", "ISO 3166-2:KP"],
  KR: ["KR", "KOR", "410", "ISO 3166-2:KR"],
  KW: ["KW", "KWT", "414", "ISO 3166-2:KW"],
  KG: ["KG", "KGZ", "417", "ISO 3166-2:KG"],
  LA: ["LA", "LAO", "418", "ISO 3166-2:LA"],
  LV: ["LV", "LVA", "428", "ISO 3166-2:LV"],
  LB: ["LB", "LBN", "422", "ISO 3166-2:LB"],
  LS: ["LS", "LSO", "426", "ISO 3166-2:LS"],
  LR: ["LR", "LBR", "430", "ISO 3166-2:LR"],
  LY: ["LY", "LBY", "434", "ISO 3166-2:LY"],
  LI: ["LI", "LIE", "438", "ISO 3166-2:LI"],
  LT: ["LT", "LTU", "440", "ISO 3166-2:LT"],
  LU: ["LU", "LUX", "442", "ISO 3166-2:LU"],
  MO: ["MO", "MAC", "446", "ISO 3166-2:MO"],
  MG: ["MG", "MDG", "450", "ISO 3166-2:MG"],
  MW: ["MW", "MWI", "454", "ISO 3166-2:MW"],
  MY: ["MY", "MYS", "458", "ISO 3166-2:MY"],
  MV: ["MV", "MDV", "462", "ISO 3166-2:MV"],
  ML: ["ML", "MLI", "466", "ISO 3166-2:ML"],
  MT: ["MT", "MLT", "470", "ISO 3166-2:MT"],
  MH: ["MH", "MHL", "584", "ISO 3166-2:MH"],
  MQ: ["MQ", "MTQ", "474", "ISO 3166-2:MQ"],
  MR: ["MR", "MRT", "478", "ISO 3166-2:MR"],
  MU: ["MU", "MUS", "480", "ISO 3166-2:MU"],
  YT: ["YT", "MYT", "175", "ISO 3166-2:YT"],
  MX: ["MX", "MEX", "484", "ISO 3166-2:MX"],
  FM: ["FM", "FSM", "583", "ISO 3166-2:FM"],
  MD: ["MD", "MDA", "498", "ISO 3166-2:MD"],
  MC: ["MC", "MCO", "492", "ISO 3166-2:MC"],
  MN: ["MN", "MNG", "496", "ISO 3166-2:MN"],
  MS: ["MS", "MSR", "500", "ISO 3166-2:MS"],
  MA: ["MA", "MAR", "504", "ISO 3166-2:MA"],
  MZ: ["MZ", "MOZ", "508", "ISO 3166-2:MZ"],
  MM: ["MM", "MMR", "104", "ISO 3166-2:MM"],
  NA: ["NA", "NAM", "516", "ISO 3166-2:NA"],
  NR: ["NR", "NRU", "520", "ISO 3166-2:NR"],
  NP: ["NP", "NPL", "524", "ISO 3166-2:NP"],
  NL: ["NL", "NLD", "528", "ISO 3166-2:NL"],
  NC: ["NC", "NCL", "540", "ISO 3166-2:NC"],
  NZ: ["NZ", "NZL", "554", "ISO 3166-2:NZ"],
  NI: ["NI", "NIC", "558", "ISO 3166-2:NI"],
  NE: ["NE", "NER", "562", "ISO 3166-2:NE"],
  NG: ["NG", "NGA", "566", "ISO 3166-2:NG"],
  NU: ["NU", "NIU", "570", "ISO 3166-2:NU"],
  NF: ["NF", "NFK", "574", "ISO 3166-2:NF"],
  MP: ["MP", "MNP", "580", "ISO 3166-2:MP"],
  MK: ["MK", "MKD", "807", "ISO 3166-2:MK"],
  NO: ["NO", "NOR", "578", "ISO 3166-2:NO"],
  OM: ["OM", "OMN", "512", "ISO 3166-2:OM"],
  PK: ["PK", "PAK", "586", "ISO 3166-2:PK"],
  PW: ["PW", "PLW", "585", "ISO 3166-2:PW"],
  PS: ["PS", "PSE", "275", "ISO 3166-2:PS"],
  PA: ["PA", "PAN", "591", "ISO 3166-2:PA"],
  PG: ["PG", "PNG", "598", "ISO 3166-2:PG"],
  PY: ["PY", "PRY", "600", "ISO 3166-2:PY"],
  PE: ["PE", "PER", "604", "ISO 3166-2:PE"],
  PH: ["PH", "PHL", "608", "ISO 3166-2:PH"],
  PN: ["PN", "PCN", "612", "ISO 3166-2:PN"],
  PL: ["PL", "POL", "616", "ISO 3166-2:PL"],
  PT: ["PT", "PRT", "620", "ISO 3166-2:PT"],
  PR: ["PR", "PRI", "630", "ISO 3166-2:PR"],
  QA: ["QA", "QAT", "634", "ISO 3166-2:QA"],
  RE: ["RE", "REU", "638", "ISO 3166-2:RE"],
  RO: ["RO", "ROU", "642", "ISO 3166-2:RO"],
  RU: ["RU", "RUS", "643", "ISO 3166-2:RU"],
  RW: ["RW", "RWA", "646", "ISO 3166-2:RW"],
  SH: ["SH", "SHN", "654", "ISO 3166-2:SH"],
  KN: ["KN", "KNA", "659", "ISO 3166-2:KN"],
  LC: ["LC", "LCA", "662", "ISO 3166-2:LC"],
  PM: ["PM", "SPM", "666", "ISO 3166-2:PM"],
  VC: ["VC", "VCT", "670", "ISO 3166-2:VC"],
  WS: ["WS", "WSM", "882", "ISO 3166-2:WS"],
  SM: ["SM", "SMR", "674", "ISO 3166-2:SM"],
  ST: ["ST", "STP", "678", "ISO 3166-2:ST"],
  SA: ["SA", "SAU", "682", "ISO 3166-2:SA"],
  SN: ["SN", "SEN", "686", "ISO 3166-2:SN"],
  SC: ["SC", "SYC", "690", "ISO 3166-2:SC"],
  SL: ["SL", "SLE", "694", "ISO 3166-2:SL"],
  SG: ["SG", "SGP", "702", "ISO 3166-2:SG"],
  SK: ["SK", "SVK", "703", "ISO 3166-2:SK"],
  SI: ["SI", "SVN", "705", "ISO 3166-2:SI"],
  SB: ["SB", "SLB", "090", "ISO 3166-2:SB"],
  SO: ["SO", "SOM", "706", "ISO 3166-2:SO"],
  ZA: ["ZA", "ZAF", "710", "ISO 3166-2:ZA"],
  GS: ["GS", "SGS", "239", "ISO 3166-2:GS"],
  ES: ["ES", "ESP", "724", "ISO 3166-2:ES"],
  LK: ["LK", "LKA", "144", "ISO 3166-2:LK"],
  SD: ["SD", "SDN", "729", "ISO 3166-2:SD"],
  SR: ["SR", "SUR", "740", "ISO 3166-2:SR"],
  SJ: ["SJ", "SJM", "744", "ISO 3166-2:SJ"],
  SZ: ["SZ", "SWZ", "748", "ISO 3166-2:SZ"],
  SE: ["SE", "SWE", "752", "ISO 3166-2:SE"],
  CH: ["CH", "CHE", "756", "ISO 3166-2:CH"],
  SY: ["SY", "SYR", "760", "ISO 3166-2:SY"],
  TW: ["TW", "TWN", "158", "ISO 3166-2:TW"],
  TJ: ["TJ", "TJK", "762", "ISO 3166-2:TJ"],
  TZ: ["TZ", "TZA", "834", "ISO 3166-2:TZ"],
  TH: ["TH", "THA", "764", "ISO 3166-2:TH"],
  TL: ["TL", "TLS", "626", "ISO 3166-2:TL"],
  TG: ["TG", "TGO", "768", "ISO 3166-2:TG"],
  TK: ["TK", "TKL", "772", "ISO 3166-2:TK"],
  TO: ["TO", "TON", "776", "ISO 3166-2:TO"],
  TT: ["TT", "TTO", "780", "ISO 3166-2:TT"],
  TN: ["TN", "TUN", "788", "ISO 3166-2:TN"],
  TR: ["TR", "TUR", "792", "ISO 3166-2:TR"],
  TM: ["TM", "TKM", "795", "ISO 3166-2:TM"],
  TC: ["TC", "TCA", "796", "ISO 3166-2:TC"],
  TV: ["TV", "TUV", "798", "ISO 3166-2:TV"],
  UG: ["UG", "UGA", "800", "ISO 3166-2:UG"],
  UA: ["UA", "UKR", "804", "ISO 3166-2:UA"],
  AE: ["AE", "ARE", "784", "ISO 3166-2:AE"],
  GB: ["GB", "GBR", "826", "ISO 3166-2:GB"],
  US: ["US", "USA", "840", "ISO 3166-2:US"],
  UM: ["UM", "UMI", "581", "ISO 3166-2:UM"],
  UY: ["UY", "URY", "858", "ISO 3166-2:UY"],
  UZ: ["UZ", "UZB", "860", "ISO 3166-2:UZ"],
  VU: ["VU", "VUT", "548", "ISO 3166-2:VU"],
  VE: ["VE", "VEN", "862", "ISO 3166-2:VE"],
  VN: ["VN", "VNM", "704", "ISO 3166-2:VN"],
  VG: ["VG", "VGB", "092", "ISO 3166-2:VG"],
  VI: ["VI", "VIR", "850", "ISO 3166-2:VI"],
  WF: ["WF", "WLF", "876", "ISO 3166-2:WF"],
  EH: ["EH", "ESH", "732", "ISO 3166-2:EH"],
  YE: ["YE", "YEM", "887", "ISO 3166-2:YE"],
  ZM: ["ZM", "ZMB", "894", "ISO 3166-2:ZM"],
  ZW: ["ZW", "ZWE", "716", "ISO 3166-2:ZW"],
  AX: ["AX", "ALA", "248", "ISO 3166-2:AX"],
  BQ: ["BQ", "BES", "535", "ISO 3166-2:BQ"],
  CW: ["CW", "CUW", "531", "ISO 3166-2:CW"],
  GG: ["GG", "GGY", "831", "ISO 3166-2:GG"],
  IM: ["IM", "IMN", "833", "ISO 3166-2:IM"],
  JE: ["JE", "JEY", "832", "ISO 3166-2:JE"],
  ME: ["ME", "MNE", "499", "ISO 3166-2:ME"],
  BL: ["BL", "BLM", "652", "ISO 3166-2:BL"],
  MF: ["MF", "MAF", "663", "ISO 3166-2:MF"],
  RS: ["RS", "SRB", "688", "ISO 3166-2:RS"],
  SX: ["SX", "SXM", "534", "ISO 3166-2:SX"],
  SS: ["SS", "SSD", "728", "ISO 3166-2:SS"],
  XK: ["XK", "XKX", "", "ISO 3166-2:XK"]
};

export default codes;
