
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomField from "../components/CustomField.vue";
import PayCardImage from "../components/payment/PayCardImage.vue";
import { VueLanguageMixin } from "../lib/language-ui";
import { Payment, PaymentPrepareRq, CustomFormField, VueForm } from "../domain/types";
import { preparePayment } from "../domain/backend";
import * as util from "../util";
import * as cardValidator from "card-validator";
import { PayCardManager } from "./payment/internal/payCardManager";

type StepName = "rejected" | "redirect" | "" | "cof" | "other";

@Component({
  components: { CustomField, PayCardImage }
})
export default class LinkPayForm extends Vue {
  @Prop() payment!: Payment;
  @Prop() backgroundStyle!: string;
  @Prop() accentColor!: string;
  @Prop() cssColor!: string;

  // Form state
  step: StepName = "";
  cofSecurityCode = "";
  cardNumber = "";
  cardExpiration = "";
  cardSecurityCode = "";

  // Payment process
  customFieldDetails: { [key: string]: boolean } = {};
  customFormValid = true;
  customFormValues: CustomFormValue[] = [];
  preparing = false;
  usedCOF = false;
  payError = "";
  payFormAction = "";
  payFormFields: Array<{ key: string; value: string }> = [];
  posting = false;

  get lang() {
    return (this.$root as VueLanguageMixin).lang;
  }

  get langCountries() {
    return (this.$root as VueLanguageMixin).langCountries;
  }

  get cardBrand() {
    const cardManager = new PayCardManager(this.lang, false, false);
    const fPAN = cardManager.pan(this.cardNumber);
    return cardManager.getTheme(fPAN.brand);
  }

  setFlipped(value: boolean) {
    (this.$refs.cardImage as PayCardImage)?.setFlipped(value);
  }

  @Watch("payment")
  onPayment() {
    this.customFormValues = [];
    if (this.payment.customFormFields) {
      for (const field of this.payment.customFormFields || []) {
        this.customFormValues.push({
          field,
          key: field.name,
          value: field.value || field.defaultValue || ""
        });
      }
    }
    if (this.payment.lastResult && !this.payment.lastResult.isApproved) {
      this.step = "rejected";
    } else {
      this.goToStart();
    }
  }

  goToStart() {
    this.goToPay();
  }

  goToPay() {
    this.step = this.hasCOF ? "cof" : "other";
  }

  created() {
    this.onPayment();
  }

  cmdProcessCustomForm() {
    this.goToPay();
  }

  async cmdPay(useCOF: boolean) {
    this.payError = "";
    this.usedCOF = useCOF;
    if (!!this.$refs.customForm && !(this.$refs.customForm as VueForm).validate()) {
      return;
    }
    const payData: PaymentPrepareRq = {
      key: this.payment.id,
      returnURL: window.location.href,
      useCOF,
      customForm: this.customFormValues.map((cval) => ({
        name: cval.key,
        value: (cval.value || "").toString()
      }))
    };
    if (useCOF) {
      if (!(this.$refs.cofCardForm as VueForm).validate()) {
        return;
      }
      payData.securityCode = this.cofSecurityCode;
    } else {
      if (!(this.$refs.cardForm as VueForm).validate()) {
        return;
      }
      payData.securityCode = this.cardSecurityCode;
      payData.pan = this.cardNumber;
      payData.expire = this.cardExpiration;
    }
    this.preparing = true;
    try {
      const formData = await preparePayment(payData);
      if (formData.onlineVersion === "puce_v2") {
        window.location.href = formData.action;
      } else {
        this.payFormAction = formData.action;
        this.payFormFields = Object.keys(formData.v1form || {}).map((key) => ({
          key,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: ((formData.v1form as any)[key] || "").toString()
        }));
        this.$nextTick(() => {
          this.submitForm();
        });
      }
    } catch (e) {
      util.logError("Error preparing", e);
      this.payError = this.lang.errPreparing;
    }
    this.preparing = false;
  }

  cmdRetry() {
    this.payError = "";
    if (this.hasCOF && this.usedCOF) {
      this.step = "cof";
    } else {
      this.step = "other";
    }
  }

  submitForm() {
    this.step = "redirect";
    this.posting = true;
    const form = this.$refs.payForm as VueForm;
    form.$el.submit();
  }

  get hasCOF(): boolean {
    return !!this.payment.id && !!this.payment.user && this.payment.user.hasStoredCard;
  }

  get cardValidation() {
    const cv = cardValidator.number(this.cardNumber);
    const result = {
      isValid: false,
      isPotentiallyValid: true,
      panMaxLength: 19,
      panLengths: [] as number[],
      panMask: "",
      panIcon: "fa-credit-card",
      codeLength: 0,
      codeMask: "####",
      codeLabel: this.lang ? this.lang.fSecurityCode : "NNNN",
      cv
    };
    let gaps = [] as number[];
    if (cv) {
      result.isValid = cv.isValid;
      result.isPotentiallyValid = cv.isPotentiallyValid;
      if (cv.card) {
        // PAN values
        result.panLengths = cv.card.lengths || [];
        gaps = cv.card.gaps || [];
        const maxlen = result.panLengths.reduce((val1, val2) => (val1 > val2 ? val1 : val2), 0);
        if (maxlen > 0) {
          result.panMaxLength = maxlen;
        }
        result.panIcon = util.getPANIcon(cv.card.type);
        // Security Code
        result.codeLabel = cv.card.code.name;
        result.codeLength = cv.card.code.size;
        result.codeMask = util.makePANMask([], result.codeLength);
      }
    }
    result.panMask = util.makePANMask(gaps, result.panMaxLength);
    return result;
  }

  get cardMask(): string {
    return this.cardValidation.panMask;
  }

  get cardRules() {
    return [
      util.rules.required(this.lang.errCardRequired),
      (cardNum: string) => cardValidator.number(cardNum).isValid || this.lang.errCardInvalid
    ];
  }

  get cardIcon(): string {
    return this.cardValidation.panIcon;
  }

  get securityCodeLabel(): string {
    return this.cardValidation.codeLabel;
  }

  get securityCodeMask(): string {
    return this.cardValidation.codeMask;
  }

  get securityCodeRules() {
    return [
      util.rules.required(this.lang.errSecurityCodeRequired),
      (code: string) =>
        !code ||
        !this.cardValidation.codeLength ||
        code.length === this.cardValidation.codeLength ||
        this.lang.errSecurityCodeInvalid
    ];
  }

  get expirationMask(): string[] {
    return ["## / ##", "## / ####"];
  }

  get expirationRules() {
    return [
      util.rules.required(this.lang.errExpirationRequired),
      (value: string) => {
        const v = cardValidator.expirationDate(value);
        return v.isPotentiallyValid || this.lang.errExpirationInvalid;
      }
    ];
  }
}

interface CustomFormValue {
  field: CustomFormField;
  key: string;
  value?: string;
}
