import { logError } from "../util/debug";

export const ERR_CODE = {
  AuthInvalid: "auth/invalid",
  InvalidParameter: "service/invalidParameter",
  NotImplemented: "service/notImplemented",
  ServiceBadConfig: "service/badConfiguration",
  ServiceBadResponseFormat: "service/badResponseFormat",
  ServiceUnhandled: "service/unhandledError",
  ServiceNetwork: "service/network",
  ServiceRemoteError: "service/remoteError",
  ApiUnhandled: "api/unhandledError",
  ApiParseError: "api/parseError",
  ApiRemote: "api/remoteError"
};

export interface MessagesIndex {
  [index: string]: string;
}

const localErrors: MessagesIndex = {
  network_error: "Problema al intentar conectar al servidor, asegúrese de tener conexión a internet.",
  app_unhandled: "Se produjo un error inesperado, pruebe de nuevo.",
  service_unhandled: "Se produjo un error remoto inesperado.",
  service_generic: "Se produjo un error inesperado, pruebe de nuevo.",
  service_invalid_parameter: "Verifique los datos ingresados.",
  "pay-invalid-state": "Esta solicitud no admite la acción enviada.",
  "pay-branch-disabled": "Contacte con PAYTEF para habilitar Payment Link en esta oficina.",
  "pay-branch-not-allowed": "Esta oficina esta deshabilitada, require configuración.",
  "pay-not-found": "Solicitud de pago no encontrada."
};

const firebaseErrors: MessagesIndex = {
  "auth/user-not-found": "No se encontró una cuenta de usuario con el correo especificado",
  "auth/operation-not-allowed": "Operación no permitida",
  "auth/network-error": "Problema al intentar conectar al servidor, asegúrese de tener conexión a internet",
  "auth/internal-error": "Error interno, reintente la operación.",
  "auth/too-many-requests": "Se han enviado muchas solicitudes al servidor, espere un momento antes de reintentar."
};

export default class AppError {
  name = "AppError";
  message: string;
  code: string;
  extraData: unknown;
  stack?: string;

  constructor(code: string, message: string, extraData: unknown) {
    this.message = message;
    this.code = code;
    this.extraData = extraData;
    this.stack = new Error(message).stack;
  }

  toString() {
    return `AppError ${this.code}: ${this.message}`;
  }
}

type ErrorType = string | null | { code: string };

export const utils = {
  HasCode(err: ErrorType) {
    return !this.GetCode(err);
  },

  GetCode(err: ErrorType): string | null {
    if (!err || typeof err === "string" || !err.code) {
      return null;
    }
    return err.code;
  },

  IsInvalidParam(err: ErrorType): boolean {
    return this.IsCode(err, [ERR_CODE.InvalidParameter]);
  },

  IsUnhandled(err: ErrorType): boolean {
    return this.IsCode(err, [
      ERR_CODE.ServiceUnhandled,
      ERR_CODE.ServiceBadResponseFormat,
      ERR_CODE.ServiceBadConfig,
      ERR_CODE.AuthInvalid,
      ERR_CODE.ServiceUnhandled,
      ERR_CODE.NotImplemented,
      ERR_CODE.ApiUnhandled,
      ERR_CODE.ApiParseError
    ]);
  },

  IsCode(err: ErrorType, codes: string[]): boolean {
    const code = this.GetCode(err);
    if (!code) {
      return false;
    }
    return codes.includes(code);
  },

  GetCodeDescription(err: ErrorType, defaultText: string): string {
    const code = this.GetCode(err);
    if (code) {
      const msg = getErrMessage(code, firebaseErrors) || getErrMessage(code, localErrors);
      if (msg) {
        return msg;
      }
      // App error or firebase error
      // TODO add a big switch to return proper message
      switch (code) {
        case ERR_CODE.ServiceNetwork:
          return localErrors.network_error;
        case ERR_CODE.ServiceUnhandled:
          return localErrors.service_unhandled;
        case ERR_CODE.ApiUnhandled:
          return localErrors.app_unhandled;
        case ERR_CODE.ServiceRemoteError:
          return localErrors.service_generic;
        case ERR_CODE.InvalidParameter:
          return localErrors.service_invalid_parameter;
        default:
          return defaultText || localErrors.service_generic;
      }
    }
    return defaultText || localErrors.service_generic;
  },

  Log(err: ErrorType, place: string, defaultText: string) {
    logError("Error in", place, ":", err, defaultText);
  }
};

function getErrMessage(code: string, catalog: MessagesIndex): string | null {
  if (catalog[code]) {
    return catalog[code];
  }
  return null;
}
