import Vue from "vue";
import { LangCountries } from "../countries";
import { LangData } from "../language";
import { defaultLanguage, languages } from "./data";
import { Language, UILanguageData, VueLanguageMixin } from "./types";

export * from "./types";

const languageData: UILanguageData = {
  languageDefault: defaultLanguage,
  languageIdx: defaultLanguage,
  languages
};

export const vueLanguageMixin = Vue.extend({
  data: () => {
    return {
      languageData
    };
  },
  computed: {
    langDef(): Partial<Language> {
      let lang = this.languageData.languages[this.languageData.languageIdx];
      if (!lang) {
        lang = this.languageData.languages[this.languageData.languageDefault];
      }
      if (!lang) {
        return {};
      }
      return lang;
    },
    lang(): LangData {
      return (this as VueLanguageMixin).langDef.data || {};
    },
    langCountries(): LangCountries {
      return (this as VueLanguageMixin).langDef.countries || {};
    },
    languages(): Language[] {
      return Object.values(this.languageData.languages);
    }
  },
  methods: {
    changeLang(newLanguage: string) {
      this.languageData.languageIdx = newLanguage;
    }
  }
});
