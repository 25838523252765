
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueLanguageMixin } from "../lib/language-ui";
import { Payment } from "../domain/types";

@Component
export default class LinkDetails extends Vue {
  @Prop() payment!: Payment;

  get lang() {
    return (this.$root as VueLanguageMixin).lang;
  }
}
