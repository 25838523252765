import { LanguageData, Language } from "./types";
import { getLangCountries } from "../countries";
import { getStrings, getLanguageNames, defaultLangKey } from "../language";

const allLangs: Language[] = getLanguageNames().map((lang) => ({
  key: lang.key,
  name: lang.name,
  data: getStrings(lang.key),
  countries: getLangCountries(lang.key)
}));

const tempLang: LanguageData = {};
for (const l of allLangs) {
  tempLang[l.key] = l;
}

export const languages = tempLang;
export const defaultLanguage = defaultLangKey;
