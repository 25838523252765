import { LangDic, LanguageName, LangGroupedDic } from ".";

import stringsDE from "./data/strings.DE";
import stringsEN from "./data/strings.EN";
import stringsES from "./data/strings.ES";
import stringsPT from "./data/strings.PT";
import stringsFR from "./data/strings.FR";
import stringsIT from "./data/strings.IT";
import stringsRU from "./data/strings.RU";

export const allStrings: LangDic = {
  ES: stringsES,
  EN: stringsEN,
  DE: stringsDE,
  PT: stringsPT,
  FR: stringsFR,
  IT: stringsIT,
  RU: stringsRU
};

export const allGroupedStrings: LangGroupedDic = {};

export const languageNames: LanguageName[] = [
  { key: "DE", name: "Deutsch" },
  { key: "EN", name: "English" },
  { key: "ES", name: "Español" },
  { key: "FR", name: "Français" },
  { key: "IT", name: "Italiano" },
  { key: "PT", name: "Português" },
  { key: "RU", name: "русский" }
];
