
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class QuickSnack extends Vue {
  snackbar = false;
  iValue = "";
  iColor = "";
  @Prop({ default: "" }) value!: string;
  @Prop({ default: "" }) color!: string;

  @Watch("value")
  changeValue(newValue?: string | null) {
    if (!newValue) {
      if (this.snackbar) {
        this.snackbar = false;
      }
    } else {
      this.iValue = newValue;
      this.snackbar = true;
    }
  }

  @Watch("color")
  changeColor(newValue: string) {
    this.iColor = newValue;
  }

  @Watch("snackbar")
  changeVisibility(newValue: boolean, oldValue: boolean) {
    if (newValue === oldValue) {
      return;
    }
    if (!newValue) {
      this.iColor = this.color;
      this.iValue = "";
      this.$emit("input", "");
    }
  }

  show(message: string, color: string) {
    this.iColor = color;
    this.iValue = message;
    this.snackbar = true;
  }
}
