
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueLanguageMixin } from "../lib/language-ui";
import { Payment, PaymentResult } from "../domain/types";
import * as filters from "../domain/filters";
import * as paymentUtil from "../domain/paymentUtil";

@Component
export default class LinkDescription extends Vue {
  @Prop() payment!: Payment;

  get lang() {
    return (this.$root as VueLanguageMixin).lang;
  }

  cardBrand(result?: PaymentResult): string {
    return filters.cardBrand(this.lang, result);
  }
  get isBizum() {
    if (this.payment.lastResult?.paymentMethod && this.payment.lastResult?.paymentMethod === "bizum") return true;
    else return false;
  }

  get isSuccess(): boolean {
    return paymentUtil.isSuccess(this.payment);
  }
}
