/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import AppError, { ERR_CODE } from "./error";

export function attachLastDash(url: string) {
  if (!url || url.length === 0) {
    return "";
  }
  // eslint-disable-next-line no-magic-numbers
  if (url.substr(-1) !== "/") {
    return `${url}/`;
  }
  return url;
}

interface CallConfig {
  method: "post" | "get";
  url: string;
  data: any;
  responseType?: string;
}

export class ServiceCall {
  tokenPromise: Promise<string> | null;

  constructor(tokenPromiseOrNull: Promise<string> | null) {
    this.tokenPromise = tokenPromiseOrNull;
  }

  Post(absoluteUrl: string, data: any) {
    return this.Request({ method: "post", url: absoluteUrl, data });
  }

  Get(absoluteUrl: string, data: any) {
    return this.Request({ method: "get", url: absoluteUrl, data });
  }

  Download(absoluteUrl: string, post: boolean, data: any) {
    return this.Request({
      method: post ? "post" : "get",
      url: absoluteUrl,
      data,
      responseType: "blob"
    });
  }

  Request(config: CallConfig) {
    return new Promise((resolve, reject) => {
      if (this.tokenPromise) {
        // Use firebase user token in the call
        this.tokenPromise
          .then((token) => {
            if (token) {
              const newConfig = {
                ...config,
                headers: { Authorization: `Bearer ${token}` }
              };
              this.runToService(newConfig, resolve, reject);
            } else {
              this.runToService(config, resolve, reject);
            }
          })
          .catch((err) => reject(err));
      } else {
        // Call without token header
        this.runToService(config, resolve, reject);
      }
    });
  }

  runToService(config: any, resolve: (data: any) => void, reject: (err: AppError) => void) {
    try {
      axios
        .request<any>({
          responseEncoding: "utf8",
          ...config
        })
        .then((httpResponse) => {
          if (config.responseType && config.responseType === "blob") {
            if (httpResponse.data && httpResponse.data.error) {
              reject(new AppError(httpResponse.data.error.code, httpResponse.data.error.message, { httpResponse }));
            } else {
              resolve(httpResponse.data);
            }
          } else {
            if (!httpResponse.data) {
              reject(new AppError(ERR_CODE.ServiceBadResponseFormat, "HTTP response has no data", { httpResponse }));
            } else if (httpResponse.data.error) {
              reject(new AppError(httpResponse.data.error.code, httpResponse.data.error.message, { httpResponse }));
            } else if (!httpResponse.data.data) {
              reject(new AppError(ERR_CODE.ServiceBadResponseFormat, "Response envelope is empty", { httpResponse }));
            } else {
              resolve(httpResponse.data.data);
            }
          }
        })
        .catch((rperr) => {
          if (rperr.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (rperr.response.data && rperr.response.data.error) {
              // There is a proper error object in data
              reject(
                new AppError(rperr.response.data.error.code, rperr.response.data.error.message, { exception: rperr })
              );
            } else {
              // No error object, probably an unhandled error in the server
              reject(
                new AppError(ERR_CODE.ServiceRemoteError, rperr.message, {
                  exception: rperr
                })
              );
            }
          } else if (rperr.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            reject(
              new AppError(ERR_CODE.ServiceNetwork, rperr.message, {
                exception: rperr
              })
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            reject(
              new AppError(ERR_CODE.ServiceBadConfig, rperr.message, {
                exception: rperr
              })
            );
          }
        });
    } catch (tryErr) {
      reject(
        new AppError(ERR_CODE.ServiceUnhandled, (tryErr as Error).toString(), {
          exception: tryErr
        })
      );
    }
  }
}
