
import { Component, Vue, Prop } from "vue-property-decorator";
import "../css/link.scss";
import * as util from "../util";
import { getPayment, getPaymentStatus, updatePayment } from "../domain/backend";
import { Payment } from "../domain/types";
import * as receipt from "../domain/receipt";
import * as paymentUtil from "../domain/paymentUtil";
import ContactText from "../components/ContactText.vue";
import LinkAttachments from "../components/LinkAttachments.vue";
import LinkPayForm from "../components/LinkPayForm.vue";
import LinkDescription from "../components/LinkDescription.vue";
import LinkDetails from "../components/LinkDetails.vue";
import QuickSnack from "../components/QuickSnack.vue";
import { VueLanguageMixin } from "../lib/language-ui";
import LangBar from "../lib/language-ui/LangBar.vue";
import LinkPayBizumForm from "../components/LinkPayBizumForm.vue";

@Component({
  components: {
    ContactText,
    LinkDescription,
    LinkDetails,
    LinkAttachments,
    LinkPayForm,
    LinkPayBizumForm,
    QuickSnack,
    LangBar
  }
})
export default class Link extends Vue {
  @Prop({ required: true }) paymentID!: string;

  // Result from the service
  payment: Partial<Payment> = paymentUtil.emptyPayment();
  error?: unknown = null;
  loading = false;
  enableAttachments = false;
  langIdx = "es";
  pdfLoading = false;
  selectedPaymentMethod: "bizum" | "card" = "card";
  selectedButton = 0;

  get vueRoot(): VueLanguageMixin {
    return this.$root as VueLanguageMixin;
  }

  get lang() {
    return this.vueRoot.lang;
  }

  get snack(): QuickSnack {
    return this.$refs.snack as QuickSnack;
  }

  get hasBizum() {
    if (this.payment.paymentMethods && this.payment.paymentMethods.includes("bizum")) return true;
    else return false;
  }

  changeSelectedPaymentMethod(method: "card" | "bizum"): void {
    this.selectedPaymentMethod = method;
  }

  async created(): Promise<void> {
    this.queryPayment()
      .then(() => {
        if (this.$route.query.pdf === "true") {
          this.cmdDownloadPDF();
        }
      })
      .catch((err) => {
        this.error = err;
      });
    await this.queryPaymentStatus(0);
  }

  async sleepFor(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async queryPaymentStatus(i: number) {
    const payment = await getPaymentStatus(this.paymentID);
    if (payment.paid === true) {
      if (!this.payment.isFinished) window.location.href = payment.redirectURL;
    } else {
      await this.sleepFor(15000);
      await this.queryPaymentStatus(++i);
    }
  }

  async queryPayment(): Promise<void> {
    this.loading = true;
    try {
      const payment = await getPayment(this.paymentID);
      this.payment = payment;
      this.langIdx = (payment.language || "es").toLowerCase();
    } catch (err) {
      this.error = err;
    }
    this.loading = false;
  }

  get hasAttachments(): boolean {
    return !!this.payment.attachedFiles && this.payment.attachedFiles.length > 0;
  }

  get showAttachments(): boolean {
    return this.hasAttachments && (!this.isSuccess || this.enableAttachments);
  }

  get isSuccess(): boolean {
    return paymentUtil.isSuccess(this.payment);
  }

  get accentColor(): string {
    if (!this.payment.id || !this.payment.company) {
      return "#BDBDBD";
    }
    return this.payment.company.primaryColor;
  }

  get cssColor(): string {
    return `color: ${this.accentColor}`;
  }

  cssBackground(percent: number): string {
    if (!this.payment.id || !this.payment.company) {
      return "#FAFAFA";
    }
    return `background-color: ${util.shadeColor(this.accentColor, percent)}`;
  }

  async cmdDownloadPDF() {
    this.pdfLoading = true;
    try {
      await receipt.downloadReceiptPDF(this.lang, this.payment);
    } catch (ex) {
      util.logError("Error creating PDF", ex);
      this.snack.show(this.lang.pdfError, "error");
    }
    this.pdfLoading = false;
  }

  get statusPanel() {
    return paymentUtil.statusPanel(this.lang, this.payment);
  }

  async onLanguageChanged(newLang: string) {
    if (!newLang || newLang.toUpperCase() === this.payment.language?.toUpperCase()) {
      return;
    }
    if (this.payment.id) {
      try {
        await updatePayment({
          key: this.payment.id,
          language: newLang
        });
      } catch (ex) {
        util.logError("Error changing language to " + newLang, ex);
      }
    }
  }
}
