import { LangData } from "../types";

const lang: LangData = {
  request_from: "Anfrage von",
  sent_to: "Gesendet an",
  operation: "Vorgang",
  due_on: "zu zahlen bis spätestens",
  price_msg:
    "Bitte geben Sie Ihre Kreditkartendaten ein! Im Anschluss werden Sie von Ihrer Bank aufgefordert Ihre Identität zu bestätigen.",
  btn_pay: "Secure Pay",
  btn_goBack: "Zurück",
  btn_goToOther: "Andere Zahlungsart wählen",
  btn_showAttachments: "Angehängtes Dokument ansehen",
  btn_retry: "Wiederholen",
  btn_downloadPDF: "PDF Herunterladen",
  rule_required: "Pflichtfeld",
  rule_maxLength: "Maximale Länge %0 Zeichen",
  rule_numeric: "Ausschließlich Zahlen erlaubt",
  pdfError: "Beim Erstellen der PDF-Datei ist ein Fehler aufgetreten",
  payCOF: "Karte:",
  payOther: "Andere Karte",
  fPANLabel: "Kartennummer",
  fExpireLabel: "MM / JJ",
  fSecurityCode: "CVC",
  loadingPaymentTitle: "Bitte warten Sie einen Moment!",
  loadingPayment: "Informationen werden geladen…",
  redirecting: "Bitte warten Sie einen Moment!",
  canceledTitle: "Zahlungsanfrage annulliert",
  canceledMessage: "Die Anfrage wurde vom Initiator annulliert.",
  expiredTitle: "Zahlungsanfrage abgelaufen.",
  expiredMessage:
    "Die für die Zahlung festgelegte Frist ist abgelaufen. Für weitere Informationen wenden Sie sich bitte an den Gläubiger.",
  rejectedMessage:
    "Die letzte Operation wurde von der ausstellenden Bank abgelehnt, überprüfen Sie die Daten und versuchen Sie es erneut.",
  resReference: "Referenznummer",
  resDate: "Zahlungsdatum",
  resPrice: "Betrag",
  resMethod: "Zahlungsform",
  resPucAuth: "Autorisierungscode",
  resMethodDefault: "Karte",
  resMessageTitle: "Danke! Die Zahlung war erfolgreich.",
  resMessageSubtitle:
    "Der Zahlungsvorgang wurde erfolgreich abgeschlossen. In Kürze erhalten Sie eine E-Mail mit den Details der Transaktion.",
  errCardRequired: "Geben Sie die Kartennummer ein!",
  errCardInvalid: "Die Kartennummer ist ungültig",
  errSecurityCodeRequired: "Kartenprüfnummer erforderlich",
  errSecurityCodeInvalid: "Ungültiger Code",
  errExpirationRequired: "Ablaufdatum erforderlich",
  errExpirationInvalid: "Das Ablaufdatum muss im Format MM/JJ eingegeben werden.",
  errLoadTitle: "Problem beim Laden der Anfrage",
  errLoad1: "Ein unerwarteter Fehler ist beim Laden der Zahlungsanfrage aufgetreten.",
  errLoad2: "Versuchen Sie, die Seite in wenigen Minuten zu aktualisieren!",
  errLoad3:
    "Wenn das Problem weiterhin besteht, prüfen Sie den Zahlungslink oder wenden Sie sich an die Firma die Ihnen den Link geschickt hat!",
  errPreparing: "Die Zahlung konnte nicht gesendet werden. Bitte überprüfen Sie die Angaben!",
  col_description: "Beschreibung",
  col_price: "Betrag",
  tbl_total: "Total",
  att_title: "Anhang",
  att_download: "Herunterladen",
  footer_start: "Wenn Sie Fragen haben, wenden Sie sich an",
  footer_call: "per Telefon",
  footer_or: "oder",
  footer_email: "per E-Mail",
  powered_by: "Powered by PAYTEF",
  page_footer_msg: "Verwenden Sie PAYTEF, um eine sichere Zahlungsabwicklung zu gewährleisten!",

  errCardNameRequired: "Die Namensangabe ist erforderlich.",
  fCardNameLabel: "Name des Karteninhabers",
  fCardNamePlaceholder: "-",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fExpirePlaceholder: "__/__",
  fPANPlaceholder: "···· ···· ···· ····",
  ciCardNameTitle: "Vollständiger Name",
  ciCardNumberTitle: "Kartennummer",
  ciExpireLabel1: "gültig bis",
  ciExpireLabel2: "",
  ciExpireTitle: "Monat/Jahr",
  ciSecurityCodeTitle: "Sicherer Prüfcode",
  tokenSubtitle: "Tokenisierungsprozess abgeschlossen.",
  tokenTitle: "Danke! Tokenisierungserfolg.",
  tokenAmount: "Tokenisierung",

  // BIZUM
  txnPaymentMethods: "ZAHLUNGSARTEN",
  txnInvalidPhone: "Das ausgewählte Telefon ist ungültig.",
  txnInvalidPhoneLength: "Die Länge des ausgewählten Telefons ist ungültig.",
  txnRequiredField: "Pflichtfeld.",
  txnPhone: "Telefon",
  txnPhoneExample: "Bsp.: ( + 34 ) 700 000 000",
  txnPaymentMethod: "BEZAHLVERFAHREN",
  btnPay: "Sichere Bezahlung",
  bizum_msg:
    "Bitte geben Sie unten Ihre Telefonnummer ein. Bizum wird Sie vor der Zahlung bitten, Ihre Identität zu bestätigen."
};

export default lang;
