interface LangCountries {
  [key: string]: string;
}

export default {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AD: "Andorra",
  AO: "Angola",
  AG: "Antigua e Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaigian",
  BS: "Bahamas",
  BH: "Bahrein",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Bielorussia",
  BE: "Belgio",
  BZ: "Belize",
  BJ: "Benin",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia ed Erzegovina",
  BW: "Botswana",
  BR: "Brasile",
  BN: "Brunei",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambogia",
  CM: "Camerun",
  CA: "Canada",
  CV: "Capo Verde",
  CF: "Rep. Centrafricana",
  TD: "Ciad",
  CL: "Cile",
  CN: "Cina",
  CO: "Colombia",
  KM: "Comore",
  CG: "Rep. del Congo",
  CD: "RD del Congo",
  CR: "Costa Rica",
  CI: "Costa d'Avorio",
  HR: "Croazia",
  CU: "Cuba",
  CY: "Cipro",
  CZ: "Rep. Ceca",
  DK: "Danimarca",
  DJ: "Gibuti",
  DM: "Dominica",
  DO: "Rep. Dominicana",
  EC: "Ecuador",
  EG: "Egitto",
  SV: "El Salvador",
  GQ: "Guinea Equatoriale",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Etiopia",
  FJ: "Figi",
  FI: "Finlandia",
  FR: "Francia",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germania",
  GH: "Ghana",
  GR: "Grecia",
  GD: "Grenada",
  GT: "Guatemala",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HN: "Honduras",
  HU: "Ungheria",
  IS: "Islanda",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Irlanda",
  IL: "Israele",
  IT: "Italia",
  JM: "Giamaica",
  JP: "Giappone",
  JO: "Giordania",
  KZ: "Kazakistan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Corea del Nord",
  KR: "Corea del Sud",
  KW: "Kuwait",
  KG: "Kirghizistan",
  LA: "Laos",
  LV: "Lettonia",
  LB: "Libano",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libia",
  LI: "Liechtenstein",
  LT: "Lituania",
  LU: "Lussemburgo",
  MK: "Macedonia del Nord",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldive",
  ML: "Mali",
  MT: "Malta",
  MH: "Isole Marshall",
  MR: "Mauritania",
  MU: "Mauritius",
  MX: "Messico",
  FM: "Micronesia",
  MA: "Marocco",
  MD: "Moldavia",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MZ: "Mozambico",
  MM: "Birmania",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Paesi Bassi",
  NZ: "Nuova Zelanda",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NO: "Norvegia",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PA: "Panama",
  PG: "Papua Nuova Guinea",
  PY: "Paraguay",
  PE: "Perù",
  PH: "Filippine",
  PL: "Polonia",
  PT: "Portogallo",
  QA: "Qatar",
  RO: "Romania",
  RU: "Russia",
  RW: "Ruanda",
  KN: "Saint Kitts e Nevis",
  LC: "Saint Lucia",
  VC: "Saint Vincent e Grenadine",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé e Príncipe",
  SA: "Arabia Saudita",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovacchia",
  SI: "Slovenia",
  SB: "Isole Salomone",
  SO: "Somalia",
  ZA: "Sudafrica",
  SS: "Sudan del Sud",
  ES: "Spagna",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SZ: "eSwatini",
  SE: "Svezia",
  CH: "Svizzera",
  SY: "Siria",
  TJ: "Tagikistan",
  TZ: "Tanzania",
  TH: "Thailandia",
  TL: "Timor Est",
  TG: "Togo",
  TO: "Tonga",
  TT: "Trinidad e Tobago",
  TN: "Tunisia",
  TR: "Turchia",
  TM: "Turkmenistan",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ucraina",
  AE: "Emirati Arabi Uniti",
  GB: "Regno Unito",
  US: "Stati Uniti",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe"
} as LangCountries;
