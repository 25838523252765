
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueLanguageMixin } from "../lib/language-ui";
import { Payment, AttachedFile } from "../domain/types";

@Component
export default class LinkAttachments extends Vue {
  @Prop() payment!: Payment;
  @Prop() cssColor!: string;

  // Other interactions
  zoomInDialog = false;
  zoomInAttachment: AttachedFile = {
    description: "",
    contentType: "",
    url: "",
    fileRef: "",
    inline: false
  };

  get lang() {
    return (this.$root as VueLanguageMixin).lang;
  }

  get inlineAtts(): AttachedFile[] {
    return this.getAttachments(true);
  }

  get downloadableAtts(): AttachedFile[] {
    return this.getAttachments(false);
  }

  getAttachments(inline: boolean): AttachedFile[] {
    if (!this.payment || !this.payment.id || !this.payment.attachedFiles) {
      return [];
    }
    return this.payment.attachedFiles.filter((att) => !!att.inline === inline);
  }

  cmdDownload(attachment: AttachedFile) {
    window.open(attachment.url);
  }

  cmdZoomIn(attachment: AttachedFile) {
    this.zoomInAttachment = attachment;
    this.zoomInDialog = true;
  }

  fileIcon(att: AttachedFile) {
    if (att.contentType.startsWith("image")) {
      return { icon: "fa-file-image", color: "#616161" };
    }
    if (att.url.includes(".xls")) return { icon: "fa-file-excel", color: "#1D6F42" };
    if (att.contentType.includes("csv")) return { icon: "fa-file-csv", color: "#1D6F42" };
    if (att.contentType.includes("pdf")) return { icon: "fa-file-pdf", color: "#f32305" };
    return { icon: "fa-file-alt", color: "#616161" };
  }
}
