import { Payment, LangData } from "./types";

export function statusPanel(lang: LangData, payment: Partial<Payment>) {
  if (payment.isCanceled) {
    return {
      iconColor: "warning",
      icon: "fa-power-off",
      title: lang.canceledTitle,
      description: lang.canceledMessage,
      showContact: true
    };
  } else if (payment.lastResult && isSuccess(payment)) {
    return {
      iconColor: "success",
      icon: "fa-check",
      title: payment.opType === "token" ? lang.tokenTitle : lang.resMessageTitle,
      description: payment.opType === "token" ? lang.tokenSubtitle : lang.resMessageSubtitle,
      showContact: false
    };
  } else if (payment.isExpired) {
    return {
      iconColor: "warning",
      icon: "fa-hourglass-end",
      title: lang.expiredTitle,
      description: lang.expiredMessage,
      showContact: true
    };
  }
  return {
    iconColor: "error",
    icon: "fa-exclamation-triangle",
    title: "Error",
    description: "La solicitud esta en un estado inválido.",
    showContact: true
  };
}

export function isSuccess(payment: Partial<Payment>): boolean {
  return !!payment.isFinished && !!payment.lastResult && payment.lastResult.isApproved;
}

export function emptyPayment(): Partial<Payment> {
  return {
    id: "",
    language: "es",
    isTest: false,
    user: {
      fullName: "",
      email: "",
      hasStoredCard: false
    },
    company: {
      name: "",
      primaryColor: "",
      secondaryColor: "",
      logoURL: "",
      logoShape: "circle",
      roundLogoURL: "",
      status: "not-found"
    },
    clientID: "",
    amountCents: 0,
    currency: "",
    expiration: "",
    customFormFields: []
  };
}
