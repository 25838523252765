import { LangData } from "../types";

const lang: LangData = {
  request_from: "Запрос от",
  sent_to: "Отправлено",
  operation: "Операция #",
  due_on: "Истекает в",
  price_msg: "Пожалуйста, заполните информацию ниже. Вам будет предложено подтвердить свою личность в своем банке.",
  btn_pay: "Безопасная оплата",
  btn_goBack: "Вернуть",
  btn_goToOther: "Используйте другой способ оплаты",
  btn_showAttachments: "См вложения",
  btn_retry: "повторить попытку",
  btn_downloadPDF: "Скачать PDF",
  rule_required: "Необходимо",
  rule_maxLength: "Максимальная длина: %0",
  rule_numeric: "Разрешены только цифры",
  pdfError: "Произошла ошибка при создании PDF",
  payCOF: "карта",
  payOther: "Другая карта",
  fPANLabel: "Номер карты",
  fExpireLabel: "MM/YY",
  fSecurityCode: "CVC",
  loadingPaymentTitle: "Пожалуйста, подождите несколько секунд",
  loadingPayment: "Загрузка информации...",
  redirecting: "Подождите немного",
  canceledTitle: "Запрос платежа отменен",
  canceledMessage: "Этот запрос был отменен его создателем.",
  expiredTitle: "Запрос на оплату истек.",
  expiredMessage: "Срок оплаты уже прошел, вы можете связаться с его создателем, чтобы узнать больше.",
  rejectedMessage:
    "Последняя попытка платежа была отклонена банком-эмитентом, проверьте свои данные и повторите попытку.",
  resReference: "Справочный номер",
  resDate: "Дата оплаты",
  resPrice: "Выплаченная сумма",
  resMethod: "Способ оплаты",
  resPucAuth: "Код авторизации",
  resMethodDefault: "карта",
  resMessageTitle: "Спасибо! Платеж произведен",
  resMessageSubtitle:
    "Процесс оплаты успешно завершен. Через мгновение вы получите электронное письмо с деталями транзакции.",
  errCardRequired: "Введите номер карты",
  errCardInvalid: "Номер карты недействителен или не поддерживается",
  errSecurityCodeRequired: "Требуется номер безопасности",
  errSecurityCodeInvalid: "Неверный код",
  errExpirationRequired: "Срок годности необходим",
  errExpirationInvalid: "Срок действия должен быть MM / YY",
  errLoadTitle: "Проблема с загрузкой запроса",
  errLoad1: "Мы обнаружили непредвиденную ошибку при загрузке этого платежного запроса.",
  errLoad2: "Попробуйте обновить страницу через несколько минут.",
  errLoad3:
    "Если проблема не устранена, проверьте правильность ссылки для оплаты или свяжитесь с компанией, которая прислала вам ссылку.",
  errPreparing: "Не удалось отправить платеж, проверьте данные.",
  col_description: "Описание",
  col_price: "Количество",
  tbl_total: "Всего",
  att_title: "Прилагаемые документы",
  att_download: "Скачать",
  footer_start: "Если у вас есть какие-либо вопросы, свяжитесь с",
  footer_call: "по телефону",
  footer_or: "или",
  footer_email: "по электронной почте",
  powered_by: "Powered by PAYTEF",
  page_footer_msg: "использует PAYTEF для обеспечения безопасной обработки платежей.",

  errCardNameRequired: "Поле обязательно для заполнения",
  fCardNameLabel: "ФИО держателя",
  fCardNamePlaceholder: "-",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fExpirePlaceholder: "__/__",
  fPANPlaceholder: "···· ···· ···· ····",
  ciCardNameTitle: "ФИО",
  ciCardNumberTitle: "Номер карты",
  ciExpireLabel1: "до",
  ciExpireLabel2: "",
  ciExpireTitle: "месяц / год",
  ciSecurityCodeTitle: "Код подтверждения",
  tokenSubtitle: "Завершен процесс токенизации.",
  tokenTitle: "Спасибо! успешная токенизация.",
  tokenAmount: "Токенизация",

  // BIZUM
  txnPaymentMethods: "СПОСОБЫ ОПЛАТЫ",
  txnInvalidPhone: "Выбранный телефон недействителен.",
  txnInvalidPhoneLength: "Длина выбранного телефона недействительна.",
  txnRequiredField: "Обязательное поле.",
  txnPhone: "Телефон",
  txnPhoneExample: "Пример: ( + 34 ) 700 000 000",
  txnPaymentMethod: "СПОСОБ ОПЛАТЫ",
  btnPay: "Безопасная оплата",
  bizum_msg:
    "Пожалуйста, заполните свой номер телефона ниже. Bizum попросит вас подтвердить свою личность перед оплатой."
};

export default lang;
