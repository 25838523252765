import Vue from "vue";
import * as format from "../util/format";
import { AnyType, isoUtcToLocal } from "../util";
import { LangData, PaymentResult } from "./types";

interface Filters {
  [ley: string]: (val: AnyType, param1?: unknown) => string;
}

const filters: Filters = {
  stDateTime: (sttimestamp: AnyType) => {
    return format.printStDateTime(sttimestamp);
  },
  boolSiNo: (anyBool: AnyType) => {
    return format.printBool(anyBool, "Sí", "No");
  },
  numDate: (numval: AnyType) => {
    return format.printDate(numval, "DDMMYYYY");
  },
  date: (numval: AnyType) => {
    return format.printDate(numval, "YYYY-MM-DD");
  },
  money: (numval: AnyType) => {
    return format.printMoney(numval);
  },
  cents: (numval: AnyType) => {
    return format.printCents(numval);
  },
  toUpper: (numval: AnyType) => {
    return toUpper(numval);
  },
  utcToLocal: (numval: AnyType) => {
    return isoUtcToLocal(numval as string, "YYYY-MM-DD HH:mm");
  }
};

for (const filterName of Object.keys(filters)) {
  Vue.filter(filterName, filters[filterName]);
}

Vue.filter("namedFilter", (val: AnyType, filterName: string) => {
  const filter = filters[filterName];
  if (filter) {
    return filter(val);
  }
  return val;
});

export function cardBrand(lang: LangData, result?: PaymentResult): string {
  if (!result) {
    return lang.resMethodDefault;
  }
  if (result.cardBrand) {
    return result.cardBrand.toUpperCase();
  }
  if (result.cardType) {
    return result.cardType.toUpperCase();
  }
  return lang.resMethodDefault;
}

export function toUpper(val?: AnyType): string {
  if (!val) {
    return "";
  }
  return val.toString().toUpperCase();
}
