import { CountryDir } from "./types";

// TODO: Make this load dynamically

import namesEN from "./data/countries.EN";
import namesES from "./data/countries.ES";
import namesDE from "./data/countries.DE";
import namesFR from "./data/countries.FR";
import namesIT from "./data/countries.IT";
import namesRU from "./data/countries.RU";
import namesPT from "./data/countries.PT";

const countries: CountryDir = {
  EN: namesEN,
  ES: namesES,
  DE: namesDE,
  FR: namesFR,
  IT: namesIT,
  RU: namesRU,
  PT: namesPT
};

export default countries;
