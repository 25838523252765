import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Link from "./views/Link.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env["BASE_URL"],
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { appbar: true }
    },
    {
      path: "/link/:paymentID",
      name: "link",
      component: Link,
      props: true,
      meta: { appbar: false }
    }
  ]
});
