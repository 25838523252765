import jsPDF from "jspdf";

export interface FontStyle {
  font?: string;
  fontSize?: number;
  color?: Color;
  style?: "normal" | "italic" | "bold" | "bolditalic";
}

interface Color {
  r: number;
  g: number;
  b: number;
}

type Alignment = "center" | "left" | "right";

interface ProperJsPDF extends jsPDF {
  //getImageProperties(imgContent: string): { width: number; height: number };
  getTextDimensions(data: string | string[]): { w: number; h: number };
}

export class PDFWriter {
  yPos: number;
  doc: ProperJsPDF;
  width: number;

  columnStartYPos = 0;
  columnMaxHeight = 0;
  columnCount = 0;
  columnCurrent: {
    idx: number;
    span: number;
    xPos: number;
    width: number;
  } | null = null;

  lineHeight = 1.5;
  margin = 15;
  innerWidth = 0;

  constructor() {
    this.yPos = 15;
    this.doc = new jsPDF({
      unit: "mm"
    }) as ProperJsPDF;
    this.width = this.doc.internal.pageSize.getWidth();
    this.fontDefault();
    this.innerWidth = this.width - this.margin * 2;
  }

  fontDefault(): void {
    this.font({
      color: this.monoColor(0),
      fontSize: 16,
      style: "normal"
    });
  }

  monoColor(greyFactor: number): Color {
    return {
      r: greyFactor,
      g: greyFactor,
      b: greyFactor
    };
  }

  font(props: FontStyle): void {
    if (props.fontSize) {
      this.doc.setFontSize(props.fontSize);
    }
    if (props.color) {
      this.doc.setTextColor(props.color.r || 0, props.color.g || 0, props.color.b || 0);
    }
    this.doc.setFont(props.font || "helvetica", props.style);
  }

  text(content: string, align: Alignment, xPos: number, style?: FontStyle): void {
    if (style) {
      this.font(style);
    }
    const dim = this.multiLineDim(content);
    this.doc.text(content, this.privCalcXPos(dim.w, align, xPos), this.yPos);
    this.yPos += dim.h * this.lineHeight;
  }

  image(imgContent: string, align: Alignment, xPos: number): void {
    const props = this.doc.getImageProperties(imgContent);
    this.doc.addImage(
      imgContent,
      this.privCalcXPos(this.pxToMM(props.width), align, xPos),
      this.yPos,
      this.pxToMM(props.width),
      this.pxToMM(props.height)
    );
    this.yPos += this.pxToMM(props.height) * this.lineHeight;
  }

  columnsStart(count: number): void {
    this.columnStartYPos = this.yPos;
    this.columnCount = count;
    this.columnMaxHeight = 0;
  }

  columnsEnd(): void {
    this.privCheckMaxColumnHeight();
    this.yPos = this.columnStartYPos + this.columnMaxHeight;
    this.columnStartYPos = 0;
    this.columnMaxHeight = 0;
    this.columnCount = 0;
    this.columnCurrent = null;
  }

  setColumn(idx: number, span?: number): void {
    this.privCheckMaxColumnHeight();
    const colWidth = this.innerWidth / this.columnCount;
    this.columnCurrent = {
      idx,
      span: span || 1,
      width: colWidth * (span || 1),
      xPos: this.margin + idx * colWidth
    };
    this.yPos = this.columnStartYPos;
  }

  verticalPad(mm: number): void {
    this.yPos += mm;
  }

  pxToMM(pixels: number): number {
    return pixels * 0.264583;
  }

  multiLineDim(content: string): { w: number; h: number } {
    const dim = this.doc.getTextDimensions(content.split("\n"));
    return dim;
  }

  hr(): void {
    const height = this.multiLineDim("A").h;
    // const extraHeight = (height * this.lineHeight) - height;
    this.verticalPad(-1 * (height / 2));
    this.doc.setLineWidth(0.1);
    this.doc.setDrawColor(200, 200, 200);
    this.doc.line(this.margin, this.yPos, this.width - this.margin, this.yPos);
    this.verticalPad(height / 2 + height * this.lineHeight);
  }

  save(fileName: string): void {
    this.doc.save(fileName);
  }

  privCalcXPos(elemWidth: number, align: Alignment, xOffset: number): number {
    let width = this.innerWidth;
    let newOffset = this.margin + xOffset;
    if (this.columnCurrent) {
      width = this.columnCurrent.width;
      newOffset = this.columnCurrent.xPos + xOffset;
    }
    if (align === "center") {
      return width / 2 - elemWidth / 2 + newOffset;
    } else if (align === "right") {
      return width - elemWidth + newOffset;
    }
    return newOffset;
  }

  privCheckMaxColumnHeight(): void {
    if (this.yPos > this.columnStartYPos) {
      const prevColHeight = this.yPos - this.columnStartYPos;
      if (this.columnMaxHeight < prevColHeight) {
        this.columnMaxHeight = prevColHeight;
      }
    }
  }
}
