export function shadeColor(rawHex: string, lumPercent: number) {
  if (!rawHex || lumPercent === 1) {
    return rawHex;
  }
  // validate hex string
  let hex = String(rawHex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const lum = lumPercent || 0;

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return (
    "#" +
    // tslint:disable-next-line:no-bitwise
    (0 | ((1 << 8) + r + (256 - r) * lum)).toString(16).substr(1) +
    // tslint:disable-next-line:no-bitwise
    (0 | ((1 << 8) + g + (256 - g) * lum)).toString(16).substr(1) +
    // tslint:disable-next-line:no-bitwise
    (0 | ((1 << 8) + b + (256 - b) * lum)).toString(16).substr(1)
  );
}
