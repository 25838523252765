import { LangKey } from "../language/types";
import codes from "./data/codes";
import { getLangCountries } from "./language";
import { LangCountries, SelectItem } from "./types";

function sort(countries: SelectItem[]): SelectItem[] {
  return countries.sort((first, second) => 0 - (first.text > second.text ? -1 : 1));
}

export function getCountryAlpha2(countries: LangCountries): SelectItem[] {
  const result = [];
  for (const code in countries) {
    const country = countries[code];
    if (country) {
      result.push({ text: country, value: code });
    }
  }
  return sort(result);
}

function getCountryItems(countries: LangCountries, attrIdx: number): SelectItem[] {
  const result = [];
  for (const code in countries) {
    const country = countries[code];
    if (country) {
      const codeAttrs = codes[code];
      if (codeAttrs && codeAttrs.length > attrIdx) {
        result.push({ text: country, value: codeAttrs[attrIdx] });
      } else {
        console.error("Codes for country not present: " + code);
      }
    }
  }
  return sort(result);
}

export function getCountryAlpha3(countries: LangCountries): SelectItem[] {
  return getCountryItems(countries, 1);
}

export function getCountryNum3(countries: LangCountries): SelectItem[] {
  return getCountryItems(countries, 2);
}

export interface CountryData {
  isoAlpha2: string;
  isoAlpha3: string;
  isoNum: string;
  name: string;
}
export function searchCountry(
  langKey: LangKey,
  terms: { alpha2?: string | null; isoNum?: string | null; alpha3?: string | null }
): CountryData | null {
  let found: string[] | null = null;
  if (terms.alpha2) {
    found = codes[terms.alpha2] || null;
  } else {
    for (const alpha2 in codes) {
      const cData = codes[alpha2];
      if (cData[1] === terms.alpha3 || cData[2] === terms.isoNum) {
        found = cData;
        break;
      }
    }
  }
  if (found == null) return null;
  return {
    isoAlpha2: found[0],
    isoAlpha3: found[1],
    isoNum: found[2],
    name: getLangCountries(langKey)[found[0]]
  };
}
