import { toBool } from "./convert";
import { Route } from "vue-router";

let lIsDebug = false;

export function isLocalhost() {
  return window.location && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");
}

export function isDevSite() {
  const firstCharacters = "paytef-dev-";
  return window.location && window.location.hostname.startsWith(firstCharacters);
}

export function isDebug() {
  return lIsDebug;
}

export function setDebug(val: boolean) {
  lIsDebug = val;
}

export function log(...params: unknown[]) {
  if (lIsDebug) {
    // tslint:disable-next-line:no-console
    console.log(...params);
  }
}

export function logError(...params: unknown[]) {
  // tslint:disable-next-line:no-console
  console.error(...params);
}

export function checkRouteDebug(route: Route) {
  if (isLocalhost()) {
    setDebug(true);
  } else if (route.query && toBool(route.query.debug)) {
    setDebug(true);
  }
}

export function promiseEnd(p: Promise<unknown>) {
  p.catch((err) => logError("Unhandled promise error", err));
}
