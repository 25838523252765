
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueLanguageMixin } from "../lib/language-ui";
import { Payment, CustomFormField, PaymentPrepareRq } from "../domain/types";
import CustomPhoneInput from "./bizum/CustomPhoneInput.vue";
import TxnProcessBizumForm from "./bizum/TxnProcessBizumForm.vue";
import { moneyToString } from "../util/convert";
import { preparePayment } from "../domain/backend";

@Component({
  components: { CustomPhoneInput, TxnProcessBizumForm }
})
export default class LinkPayBizumForm extends Vue {
  @Prop() payment!: Payment;
  @Prop() backgroundStyle!: string;
  @Prop() accentColor!: string;
  @Prop() cssColor!: string;

  // Payment process
  customFieldDetails: { [key: string]: boolean } = {};
  customFormValid = true;
  customFormValues: CustomFormValue[] = [];
  preparing = false;
  usedCOF = false;
  payError = "";
  payFormAction = "";
  payFormFields: Array<{ key: string; value: string }> = [];
  posting = false;
  phone = "";
  processing = false;

  get vueRoot(): VueLanguageMixin {
    return this.$root as VueLanguageMixin;
  }

  get lang() {
    return this.vueRoot.lang;
  }

  get txnAmount(): string {
    return moneyToString((this.payment?.amountCents / 100)?.toFixed(2), this.payment?.currency);
  }

  get disableBizumButton() {
    if (this.phone && !this.processing) return (this.$refs.phoneInput as CustomPhoneInput).isPhoneInvalid();
    return true;
  }

  async cmdPay() {
    this.payError = "";
    if ((this.$refs.phoneInput as CustomPhoneInput).isPhoneInvalid()) return;

    const payData: PaymentPrepareRq = {
      key: this.payment.id,
      returnURL: window.location.href,
      useCOF: false,
      customForm: this.customFormValues.map((cval) => ({
        name: cval.key,
        value: (cval.value || "").toString()
      })),
      paymentMethod: "bizum",
      phone: this.phone
    };
    this.preparing = true;
    this.processing = true;
    try {
      const formData = await preparePayment(payData);
      if (formData.onlineVersion === "puce_v2") {
        window.location.href = formData.action;
      } else {
        this.payFormAction = formData.action;
        this.payFormFields = Object.keys(formData.v1form || {}).map((key) => ({
          key,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: ((formData.v1form as any)[key] || "").toString()
        }));
        // this.$nextTick(() => {
        //   this.submitForm();
        // });
      }
    } catch (e) {
      // util.logError("Error preparing", e);
      this.payError = this.lang.errPreparing;
    }
    this.processing = false;
    this.preparing = false;
  }

  cmdRetry() {
    this.payError = "";
  }
}

interface CustomFormValue {
  field: CustomFormField;
  key: string;
  value?: string;
}
