import Vue from "vue";
// tslint:disable-next-line:no-implicit-dependencies
import "@fortawesome/fontawesome-free/css/all.css";
import vuetify from "./plugins/vuetify";
import "./css/common.scss";
import "./domain/filters";
import App from "./App.vue";
import router from "./router";
import { vueLanguageMixin } from "./lib/language-ui";

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  mixins: [vueLanguageMixin],
  data: () => {
    return {};
  }
}).$mount("#app");
