// --- Not used masks ---
// {
//     mask: '####-####-####-####',
//     regex: '^(5019|4175|4571)\\d{0,12}',
//     brand: 'dankort'
// },
// {
//     mask: '####-####-####-####',
//     regex: '^63[7-9]\\d{0,13}',
//     brand: 'instapayment'
// },
// {
//     mask: '####-####-####-####',
//     regex: '^220[0-4]\\d{0,12}',
//     brand: 'mir'
// },

import { CardLang, CardBrandDetails } from "./types";

export const defaultLang: CardLang = {
  fCardNameLabel: "Nombre del titular",
  fCardNamePlaceholder: "-",
  fPANLabel: "Número de tarjeta",
  fPANPlaceholder: "XXXX XXXX XXXX XXXX",
  fExpireLabel: "MM / AA",
  fExpirePlaceholder: "__/__",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  errCardRequired: "Ingrese el número de tarjeta",
  errCardInvalid: "Número de tarjeta inválido",
  errSecurityCodeRequired: "Número de seguridad necesario",
  errSecurityCodeInvalid: "Código inválido",
  errExpirationRequired: "Fecha de expiración necesaria",
  errExpirationInvalid: "La expiración debe ser MM / AA",
  errCardNameRequired: "Campo obligatorio"
};

export const brandDetails: CardBrandDetails[] = [
  {
    mask: "#### ###### #####",
    regex: "^3[47]\\d{0,13}",
    brand: "amex",
    paytefCode: "AX",
    name: "American Express"
  },
  {
    mask: "#### #### #### ####",
    regex: "^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}",
    brand: "discover",
    paytefCode: "unknown",
    name: "Discover"
  },
  {
    mask: "#### ###### ####",
    regex: "^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}",
    brand: "diners",
    paytefCode: "DC",
    name: "Diners Club"
  },
  {
    mask: "#### #### #### ####",
    regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
    brand: "mastercard",
    paytefCode: "MC",
    name: "MasterCard"
  },
  {
    mask: "#### ###### #####",
    regex: "^(?:2131|1800)\\d{0,11}",
    brand: "jcb15",
    paytefCode: "JC",
    name: "JCB"
  },
  {
    mask: "#### #### #### ####",
    regex: "^(?:35\\d{0,2})\\d{0,12}",
    brand: "jcb",
    paytefCode: "JC",
    name: "JCB"
  },
  {
    mask: "#### #### #### ####",
    regex: "^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}",
    brand: "maestro",
    paytefCode: "MA",
    name: "Maestro"
  },

  {
    mask: "#### #### #### ####",
    regex: "^4\\d{0,15}",
    brand: "visa",
    paytefCode: "VI",
    name: "Visa"
  },
  {
    mask: "#### #### #### ####",
    regex: "^62\\d{0,14}",
    brand: "unionpay",
    paytefCode: "UP",
    name: "Union Pay"
  },
  {
    mask: "#### #### #### ####",
    brand: "unknown",
    paytefCode: "unknown",
    name: "Unknown"
  }
];
