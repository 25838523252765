import { LangData } from "../types";

const lang: LangData = {
  request_from: "Solicitação de",
  sent_to: "Enviado para",
  operation: "Operação",
  due_on: "expira em",
  price_msg:
    "Por favor, preencha suas informações abaixo. Seu banco solicitará que você valide sua identidade antes de pagar.",
  btn_pay: "Pagamento seguro",
  btn_goBack: "Retornar",
  btn_goToOther: "Use outra forma de pagamento",
  btn_showAttachments: "Veja documentos em anexo",
  btn_retry: "Tente novamente",
  btn_downloadPDF: "Baixar PDF",
  rule_required: "O campo obrigatório",
  rule_maxLength: "Comprimento máximo %0 caracteres",
  rule_numeric: "Este campo suporta apenas números",
  pdfError: "Ocorreu um erro ao criar o PDF",
  payCOF: "Cartão:",
  payOther: "Outro cartão",
  fPANLabel: "Número de cartão",
  fExpireLabel: "MM / AA",
  fSecurityCode: "CVC",
  loadingPaymentTitle: "Espere um momento",
  loadingPayment: "Carregando informações...",
  redirecting: "Espere um momento",
  canceledTitle: "Pedido cancelado",
  canceledMessage: "Esta solicitação de pagamento foi cancelada pelo seu criador.",
  expiredTitle: "A solicitação de pagamento expirou.",
  expiredMessage:
    "O tempo máximo estipulado para pagamento já passou, você pode solicitar a renovação caso não esteja correto.",
  rejectedMessage: "A última operação foi negada pelo banco emissor, verifique os dados e tente novamente.",
  resReference: "Número de referência",
  resDate: "Data de pagamento",
  resPrice: "Montante",
  resMethod: "Método de pagamento",
  resPucAuth: "Código de autorização",
  resMethodDefault: "Cartão",
  resMessageTitle: "Obrigado! Pagamento bem sucedido.",
  resMessageSubtitle:
    "Você concluiu o processo de pagamento, em breve receberá um e-mail com os detalhes da transação.",
  errCardRequired: "Digite o número do cartão",
  errCardInvalid: "Número de cartão inválido",
  errSecurityCodeRequired: "Número de segurança obrigatório",
  errSecurityCodeInvalid: "Código inválido",
  errExpirationRequired: "Data de expiração necessária",
  errExpirationInvalid: "A expiração deve ser MM/AA",
  errLoadTitle: "Problema ao carregar solicitação",
  errLoad1: "Encontramos um erro inesperado ao fazer o upload da solicitação de pagamento.",
  errLoad2: "Tente atualizar a página em alguns minutos.",
  errLoad3:
    "Se o problema persistir, verifique se o link de pagamento está correto ou entre em contato com a empresa que o enviou.",
  errPreparing: "Não foi possível enviar o pagamento, verifique os dados.",
  col_description: "Descripción",
  col_price: "Montante",
  tbl_total: "Total",
  att_title: "Anexos",
  att_download: "Baixar",
  footer_start: "Se você tiver dúvidas, entre em contato",
  footer_call: "ligando para",
  footer_or: "ou",
  footer_email: "enviando um e-mail",
  powered_by: "Powered by PAYTEF",
  page_footer_msg: "usa PAYTEF para realizar o processamento seguro de transações.",

  errCardNameRequired: "Campo obrigatório",
  fCardNameLabel: "Nome do titular",
  fCardNamePlaceholder: "-",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fExpirePlaceholder: "__/__",
  fPANPlaceholder: "···· ···· ···· ····",
  ciCardNameTitle: "Nome do titular",
  ciCardNumberTitle: "Número do cartão",
  ciExpireLabel1: "até",
  ciExpireLabel2: "",
  ciExpireTitle: "mês/ano",
  ciSecurityCodeTitle: "Cód.verificação",
  tokenSubtitle: "Concluído o processo de tokenização.",
  tokenTitle: "Obrigado! tokenização bem-sucedida.",
  tokenAmount: "Tokenização",

  // BIZUM
  txnPaymentMethods: "MEIOS DE PAGAMENTO",
  txnInvalidPhone: "O telefone selecionado não é válido.",
  txnInvalidPhoneLength: "O comprimento do telefone selecionado não é válido.",
  txnRequiredField: "O campo obrigatório.",
  txnPhone: "Telefone",
  txnPhoneExample: "Ex: (+34) 700 000 000",
  txnPaymentMethod: "MEIO DE PAGAMENTO",
  btnPay: "pagamento seguro",
  bizum_msg:
    "Por favor, preencha o seu número de telefone abaixo. A Bizum solicitará que você valide sua identidade antes de pagar."
};

export default lang;
