import * as convert from "./convert";
import { AnyType } from "./convert";
import * as validation from "./validation";
import * as dates from "./dates";
import * as debug from "./debug";

function anyToString(val: AnyType): string {
  if (val === null || val === undefined) return "";
  return val.toString();
}

export function printStDateTime(stTimestamp: AnyType): string {
  if (validation.isEmpty(stTimestamp)) {
    return "-";
  }
  const num = convert.toNumber(stTimestamp);
  return dates.toDateTimeString(dates.parseStDateTime(num));
}

export function printDate(numVal: AnyType, format: string = dates.ISO_FORMAT): string {
  if (validation.isEmpty(numVal) || typeof numVal !== "string") {
    return "-";
  }
  return dates.toDateString(dates.parseDate(numVal, format));
}

export function printAsISODate(numVal: AnyType, format: string): string {
  if (validation.isEmpty(numVal) || typeof numVal !== "string") {
    return "-";
  }
  return dates.toDateString(dates.parseDate(numVal, format), dates.ISO_FORMAT);
}

export function printTimestamp(val: AnyType, format: string = dates.ISO_DT_FORMAT): string {
  if (validation.isEmpty(val) || typeof val !== "string") {
    return "-";
  }
  return dates.toDateTimeString(dates.parseDate(val, format));
}

export function printCents(anyAmount: AnyType, decimalCount = 2, decimal = ",", thousands = "."): string {
  if (validation.isNull(anyAmount)) {
    return "-";
  }
  const amount = convert.toNumber(anyAmount);
  if (isNaN(amount)) {
    return `invalid[${anyAmount}]`;
  }
  return printMoney(amount / 100, decimalCount, decimal, thousands);
}

export function printMoney(anyAmount: AnyType, pDecimalCount = 2, decimal = ",", thousands = "."): string {
  if (validation.isNull(anyAmount)) {
    return "-";
  }
  const amount = convert.toNumber(anyAmount);
  try {
    let decimalCount = Math.abs(pDecimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const absAmount = Math.abs(Number(amount) || 0);
    const fixedPrint = absAmount.toFixed(decimalCount);
    const intAmount = parseInt(fixedPrint, 10);
    const intPrint = intAmount.toString();
    const thTriplets = intPrint.length > 3 ? intPrint.length % 3 : 0;

    return (
      negativeSign +
      (thTriplets ? intPrint.substr(0, thTriplets) + thousands : "") +
      intPrint.substr(thTriplets).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(absAmount - intAmount)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    debug.logError("Error formatting money: ", anyAmount, e);
    return anyToString(anyAmount);
  }
}

export function printBool(val: AnyType, trueVal: string, falseVal: string): string {
  if (validation.isNull(val)) {
    return "-";
  }
  const boolVal = convert.toBoolOrNull(val);
  if (boolVal === null) {
    return anyToString(val);
  }
  return boolVal ? trueVal : falseVal;
}
