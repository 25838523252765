import * as debug from "./debug";

export type AnyType = string | number | null | undefined | object;

export function toNumber(val: AnyType): number {
  if (val === null || val === undefined) {
    return 0;
  }
  return Number(val);
}

export function moneyToNumber(val: AnyType): number {
  if (val === null || val === undefined) {
    return 0;
  }
  let valToParse = val;
  if (typeof val === "string") {
    valToParse = val.replace(/\./g, "").replace(/,/g, ".");
  }
  return Number(valToParse);
}

export function moneyToString(amount?: string | null, currency?: string | null): string {
  if (!amount) return "";
  switch (currency || "EUR") {
    case "EUR":
      return "€" + amount;
    case "USD":
      return "US$ " + amount;
    default:
      return currency + " " + amount;
  }
}

export function toString(val: AnyType): string | undefined {
  if (val === null || val === undefined) {
    return undefined;
  }
  if (typeof val === "string") {
    return val;
  }
  return val.toString();
}

export function toFixed(val: number | null | undefined, decimals: number): string {
  if (val === null || val === undefined) {
    return "0";
  }
  return toNumber(val).toFixed(decimals);
}

export function toNumberSafe(val: AnyType): number {
  const converted = toNumber(val);
  if (isNaN(converted)) {
    return 0;
  }
  return converted;
}

export function toBoolOrNull(val: AnyType): boolean | null {
  if (val === null || val === undefined || val === "") {
    return false;
  }
  if (typeof val === "string") {
    const lowC = val.toLowerCase();
    if (lowC === "true" || lowC === "s" || lowC === "y" || lowC === "1" || lowC === "yes" || lowC === "si") {
      return true;
    } else if (lowC === "false" || lowC === "n" || lowC === "0" || lowC === "no") {
      return false;
    }
    return null;
  }
  return !!val;
}

export function toBool(val: AnyType): boolean {
  const parsedBool = toBoolOrNull(val);
  if (parsedBool === null) {
    debug.logError("Invalid boolean string: '", val, "', returning default");
    return !!val;
  }
  return parsedBool;
}
